export default defineNuxtPlugin(() => {
  const loader = new OnDemand(
    'https://cdnjs.cloudflare.com/ajax/libs/lottie-player/2.0.4/lottie-player.min.js',
    { delay: 0 },
  )

  return {
    provide: {
      lottiePlayer: {
        init(callback) {
          loader.load(() => callback())
        },
      },
    },
  }
})
