<template>
  <footer
    class="footer"
    :class="props.theme"
  >
    <!-- Part 1 > Sitemap -->
    <div class="sitemap">
      <div class="about">
        <div class="company">
          <img
            v-if="props.theme === 'dark'"
            alt="Livestorm Logo"
            class="logo"
            height="24"
            loading="lazy"
            src="@/assets/svg/logo-white.svg"
            width="143"
          >
          <img
            v-else
            alt="Livestorm Logo"
            class="logo"
            height="24"
            loading="lazy"
            src="@/assets/svg/logo-black.svg"
            width="143"
          >
          <UiComponentSelect
            v-if="availableLocales.length > 1"
            id="languages"
            has-icon
            :index="currentLocaleIndex"
            :options="availableLocales"
            :theme="props.theme"
            @select:change="changeItem"
          />
        </div>
        <div
          v-if="address"
          class="address"
          v-html="address"
        />
        <div class="copyright">
          {{ copyright }}
        </div>
        <div class="social-networks">
          <AtomAnchor
            v-for="network in data.allSocialNetworks"
            :key="network.id"
            :aria-label="network.name"
            :force-no-follow="network.forceNoFollow"
            :to="network.link"
            @click="
              network.name
                ? track('Share clicked', {
                  category: 'Button',
                  label: `${network.name} footer`,
                })
                : null
            "
          >
            <UiComponentIcon
              v-if="network.icon"
              :theme="props.theme"
              :type="network.icon.name"
            />
          </AtomAnchor>
        </div>
      </div>
      <div
        v-for="(item, index) in data.allPrimaryItems"
        :key="item.id"
        class="column"
        :class="`column-${index + 1}`"
      >
        <div
          class="title"
          @click="
            selectedItemId === item.id
              ? (selectedItemId = null)
              : (selectedItemId = item.id)
          "
        >
          <p>
            {{ item.name }}
          </p>
          <UiComponentIcon
            :type="selectedItemId === item.id ? 'minus' : 'plus'"
          />
        </div>
        <div
          class="links"
          :class="{ active: selectedItemId === item.id }"
        >
          <div
            v-for="
              link in item.links
                .filter(({ isDisplayedOnlyWhenLoggedIn }) => {
                  if (isDisplayedOnlyWhenLoggedIn) {
                    return isLogged;
                  }

                  return true;
                })
            "
            :key="link.id"
          >
            <AtomAnchor
              v-if="link.link !== '#/'"
              :force-no-follow="link.forceNoFollow"
              :to="link.link"
              :data-cy="setData(link)"
              @click="
                track('Footer clicked', {
                  category: 'Button',
                  label: link.link,
                  locale,
                })
              "
            >
              <p class="link">
                {{ link.name }}
              </p>
            </AtomAnchor>
            <div
              v-if="link.action"
              class="link"
              :data-cy="setData(link)"
              @click="itemAction(link)"
            >
              <button
                class="text-left cursor-pointer"
                type="button"
              >
                {{ link.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Part 2 > Certification -->
    <div class="certifications">
      <UiComponentImage
        v-for="{ id, medal } in data.extraInformations.certifications"
        :key="`badge-${id}-${badgesSize}`"
        :alt="medal.alt"
        :expected-size="badgesSize"
        :extra-params="{
          trim: 'color',
        }"
        :height="medal.height"
        :orientation="medal.width > medal.height ? 'portrait' : 'landscape'"
        :source="medal.url"
        use-natural-height
        use-natural-width
        :width="medal.width"
      />
    </div>

    <!-- Part 3 > SEO -->
    <div
      v-if="data.allSecondaryItems && data.allSecondaryItems.items && data.allSecondaryItems.items.length > 0"
      class="seo"
    >
      <div
        v-for="item in data.allSecondaryItems.items"
        :key="item.id"
        class="section"
      >
        <div class="head">
          <AtomAnchor
            v-if="item.link"
            :to="item.link"
            @click="
              track('Footer clicked', {
                category: 'Button',
                label: item.link,
                locale,
              })
            "
          >
            {{ item.title }}
          </AtomAnchor>
          <div v-else>
            {{ item.title }}
          </div>
        </div>
        <div class="body">
          <AtomAnchor
            v-for="child in item.children"
            :key="child.id"
            :to="child.link"
            @click="
              track('Footer clicked', {
                category: 'Button',
                label: child.link,
                locale,
              })
            "
          >
            {{ child.title }}
          </AtomAnchor>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
// Composables
const { track } = useAnalyticsJS()
const { languages } = useAvailableLanguages()
const { state } = useConditionalPreviewMode()
const { isModalActive } = useCookieControl()
const { locale } = useI18n()
const { isLogged } = useIsLoggedIn()
const { $interpolate } = useNuxtApp()
const { width: screenWidth } = useScreenSize()
const switchLocalePath = useSwitchLocalePath()

// Props
const props = defineProps({
  theme: {
    default() {
      return 'light'
    },
    required: false,
    type: String,
    validator(value) {
      return ['dark', 'light'].indexOf(value) !== -1
    },
  },
})

// Async Data
const { data } = await useFetch(`/api/content/components/footer/${locale.value}`, {
  key: `component-footer-${locale.value}`,
  query: {
    preview: state.preview,
  },
})

// Cookies
const preferences = useCookie('preferences')

// States
const forms = useState('forms')

// Refs
const address = ref(null)
const selectedItemId = ref(null)

// Computed Properties
const availableLocales = computed(() => {
  return languages.value.map(({ name, slug: value }) => ({
    name,
    value,
  }))
})

const badgesSize = computed(() => {
  return screenWidth.value < 768 ? 55 : 68
})

const copyright = computed(() => {
  return $interpolate(data.value.extraInformations.wordingLivestormCopyright, {
    '%{year}': new Date().getFullYear(),
  })
})

const currentLocaleIndex = computed(() => {
  return languages.value.findIndex(({ slug }) => slug === locale.value)
})

// Methods
function changeItem(locale) {
  track('Language switch clicked', {
    category: 'Button',
  })
  const { slug } = languages.value.find(({ slug }) => slug === locale)
  const url = switchLocalePath(slug).split('?').shift()
  console.log(url)
  preferences.value = {
    locale,
  }
  window.location.replace(url)
}

async function getAddress() {
  if (import.meta.client) {
    const { default: inEU } = await import('@segment/in-eu')
    return inEU()
      ? data.value.extraInformations.europeanAddress
      : data.value.extraInformations.americanAddress
  }
  else {
    return null
  }
}

function itemAction(link) {
  if (link.action) {
    if (link.action === 'show-cookies') {
      showCookieModal()
    }
    if (link.action === 'request-demo') {
      track('Demo clicked', { category: 'Button' })
      forms.value.demo.isVisible = true
    }
  }
}

function setData(link) {
  if (link.link === 'mailto:sales@livestorm.co') return 'contact-sales'
  else if (link.link === '#/') {
    if (link.action === 'request-demo') return 'request-demo'
    if (link.action === 'show-cookies') return 'show-cookies'
  }
  else return null
}

function showCookieModal() {
  isModalActive.value = true
}

onMounted(async () => {
  address.value = await getAddress()
})
</script>

<style lang="postcss" scoped>
.footer {
  @apply flex flex-col w-full mt-12;

  @screen md {
    @apply mt-16;
  }

  &.dark {
    .sitemap {
      .about {
        .address {
          @apply text-white;

          .contact {
            .support {
              p {
                @apply text-white;
              }
            }
          }

          .copyright {
            @apply text-white;
          }
        }
      }

      .column {
        &:last-child {
          .title {
            @apply border-[#0E1C20];
          }
        }

        .title {
          @apply border-[#0E1C20];

          p {
            @apply text-white;
          }
        }

        .links {
          .link {
            @apply text-[#CED0D0] border-[#0E1C20];

            &:last-child {
              @apply border-[#0E1C20];
            }

            &:hover {
              @apply text-white;
            }
          }
        }
      }
    }

    .seo {
      .section {
        .head {
          a,
          div {
            @apply text-white;
          }

          a {
            &:hover {
              @apply text-white;
            }
          }
        }

        .body {
          a {
            @apply text-[#CED0D0];

            &:hover {
              @apply text-white;
            }
          }
        }
      }
    }
  }

  &.light {
    .sitemap {
      .about {
        .address {
          @apply text-grey-blue-600;
        }

        .contact {
          .support {
            p {
              @apply text-grey-blue-600;
            }
          }
        }

        .copyright {
          @apply text-grey-blue-600;
        }
      }

      .column {
        &:last-child {
          .title {
            @apply border-grey-blue-200;
          }
        }

        .title {
          @apply border-grey-blue-200;

          p {
            @apply text-winter-green-900;
          }
        }

        .links {
          .link {
            @apply text-grey-blue-600 border-grey-blue-200;

            &:last-child {
              @apply border-grey-blue-200;
            }

            &:hover {
              @apply text-livestorm-blue-700;
            }
          }
        }
      }
    }

    .seo {
      @apply bg-grey-blue-100;

      &::after,
      &::before {
        @apply bg-grey-blue-100;
      }

      .section {
        .head {
          a,
          div {
            @apply text-winter-green-900;
          }

          a {
            &:hover {
              @apply text-livestorm-blue-700;
            }
          }
        }

        .body {
          a {
            @apply text-grey-blue-600;

            &:hover {
              @apply text-livestorm-blue-700;
            }
          }
        }
      }
    }
  }

  .sitemap {
    @apply flex flex-wrap justify-between w-full m-auto;

    @screen md {
      @apply justify-start;
    }

    @screen xl {
      @apply flex-nowrap
    }

    .about {
      @apply flex flex-col w-full mb-12;

      @screen md {
        @apply mb-16;
      }

      @screen xl {
        @apply w-[180px] mb-0;
      }

      .company {
        @apply flex flex-col items-start gap-6;

        @screen md {
          @apply flex-row justify-between gap-0;
        }

        @screen xl {
          @apply flex-col justify-start gap-8;
        }

        .logo {
          @apply flex h-8 w-auto;
        }

        .select {
          @apply max-w-[194px];
        }
      }

      .address {
        @apply mt-6 text-sm leading-6;

        @screen md {
          @apply mt-4;
        }

        @screen xl {
          @apply mt-8;
        }
      }

      .copyright {
        @apply mt-2 text-sm leading-6;
      }

      .social-networks {
        @apply flex gap-4 mt-6;

        @screen md {
          @apply mt-4;
        }

        @screen xl {
          @apply mt-8;
        }

        a {
          @apply flex justify-center items-center h-4 w-4;

          i {
            @apply text-base leading-none;
          }
        }
      }
    }

    .column {
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      @apply relative w-screen;

      @screen md {
        @apply mx-5 left-0 right-0;
        width: calc((100% - 120px) / 4);
      }

      @screen xl {
        @apply w-[180px];
      }

      &.column-1 {
        @screen md {
          @apply ml-0;
        }

        @screen xl {
          @apply ml-auto;
        }
      }

      &:last-child {
        .title {
          @apply border-b;
        }

        @screen md {
          @apply mr-0;
        }
      }

      .title {
        @apply p-4 flex justify-between items-center border-t cursor-pointer;

        @screen md {
          @apply p-0 border-none;
        }

        p {
          @apply capitalize text-sm font-medium;
        }

        i {
          @screen md {
            @apply hidden;
          }
        }
      }

      .links {
        transition-property: max-height;
        will-change: max-height;
        @apply max-h-0 overflow-hidden duration-600 ease-in-out;

        @screen md {
          /* max-width: 149px; */
          @apply flex flex-col max-h-full;
        }

        &.active {
          max-height: 3000px;
          @apply flex flex-col h-full;
        }

        .link {
          @apply py-3 px-4 text-sm border-t leading-[22px];

          @screen md {
            @apply mt-4 p-0 bg-transparent border-none;
          }

          &:last-child {
            @apply border-b;
          }
        }
      }
    }
  }

  .certifications {
    @apply flex flex-row flex-wrap items-center justify-center gap-6 py-12;

    @screen md {
      @apply py-16;
    }

    @screen xl {
      @apply justify-start py-8;
    }

    picture {
      @apply rounded-none;
    }
  }

  .seo {
    @apply relative flex flex-col py-4;

    @screen md {
      @apply py-10;
    }

    &::before {
      @apply absolute -left-4 top-0 flex h-full w-4 content-[''];

      @screen md {
        @apply -left-6 w-6;
      }

      @screen xl {
        left: calc((100vw - 1280px) / -2);
        width: calc((100vw - 1280px) / 2);
      }
    }

    &::after {
      @apply absolute -right-4 top-0 flex h-full w-4 content-[''];

      @screen md {
        @apply -right-6 w-6;
      }

      @screen xl {
        right: calc((100vw - 1280px) / -2);
        width: calc((100vw - 1280px) / 2);
      }
    }

    .section {
      @apply flex flex-col w-full mt-4;

      @screen md {
        @apply flex-row flex-wrap;
      }

      @screen xl {
        @apply mt-2;
      }

      &:first-child {
        @apply mt-0;
      }

      .head {
        @apply flex;

        @screen md {
          @apply w-full max-w-[86px];
        }

        a,
        div {
          @apply flex font-medium text-base text-winter-green-900 leading-12;

          @screen xl {
            @apply text-xs leading-6;
          }
        }

        a {
          &:hover {
            @apply text-livestorm-blue-700;
          }
        }
      }

      .body {
        @apply flex flex-col;

        @screen md {
          @apply flex-row flex-wrap ml-6;
          width: calc(100% - 110px);
        }

        a {
          @apply font-normal text-base text-grey-blue-600 leading-12;

          @screen md {
            @apply mr-6;
          }

          @screen xl {
            @apply text-xs leading-6;
          }

          &:hover {
            @apply text-livestorm-blue-700;
          }

          &:last-child {
            @apply mr-0;
          }
        }
      }
    }
  }
}
</style>
