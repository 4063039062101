export default defineNuxtPlugin(() => {
  const loader = new OnDemand(
    'https://cdnjs.cloudflare.com/ajax/libs/hls.js/1.3.5/hls.min.js',
    { delay: 0 },
  )

  return {
    provide: {
      hls: {
        init(callback) {
          loader.load(() => callback())
        },
      },
    },
  }
})
