<template>
  <Component
    :is="is"
    ref="$el"
    class="anchor"
    v-bind="attributes"
  >
    <slot />
  </Component>
</template>

<script setup>
// Runtime config
const { public: publicRuntimeConfig } = useRuntimeConfig()

// Props
const props = defineProps({
  forceNoFollow: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  forceReload: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  to: {
    default() {
      return undefined
    },
    required: false,
    type: String,
  },
  target: {
    default() {
      return undefined
    },
    required: false,
    type: String,
  },
})

// Refs
const $el = ref(null)

// Computed Properties
const attributes = computed(() => {
  if (!props.to) {
    return {}
  }
  else if (props.forceReload) {
    return {
      href: parsedUrl.value.startsWith('/')
        ? parsedUrl.value
        : `/${parsedUrl.value}`,
      rel: props.forceNoFollow ? 'nofollow' : undefined,
      target: props.target,
    }
  }
  else if (parsedUrl.value.startsWith('#')) {
    return {}
  }
  else {
    return isURL(parsedUrl.value)
      ? {
          href: parsedUrl.value,
          rel: props.forceNoFollow
            ? 'noopener nofollow'
            : isALivestormDomain(parsedUrl.value)
              ? 'noopener'
              : 'noopener nofollow',
          target: '_blank',
        }
      : {
          rel: props.forceNoFollow ? 'nofollow' : undefined,
          to: parsedUrl.value.startsWith('/')
            ? parsedUrl.value
            : `/${parsedUrl.value}`,
          target: props.target,
        }
  }
})

const is = computed(() => {
  if (!props.to) {
    return 'div'
  }
  else if (props.forceReload) {
    return 'a'
  }
  else if (parsedUrl.value.startsWith('#')) {
    return 'div'
  }
  else {
    return isURL(parsedUrl.value) ? 'a' : resolveComponent('NuxtLink')
  }
})

const parsedUrl = computed(() => {
  if (!props.to) {
    return undefined
  }
  else if (
    publicRuntimeConfig.appBaseUrl.includes('livestorm.io')
    && props.to?.includes('app.livestorm.co')
  ) {
    return props.to.replace('app.livestorm.co', publicRuntimeConfig.appBaseUrl)
  }
  return props.to
})

// Methods
function isALivestormDomain(url) {
  return (
    (url.includes('livestorm.co') && !url.includes(publicRuntimeConfig.appBaseUrl)) || url.includes('video-engagement.org')
  )
}

function isURL(to) {
  const pattern
    = /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi
  return pattern.test(to)
}

// Expose
defineExpose({
  $el,
})
</script>
