<template>
  <div
    :id="uniqueId"
    ref="$el"
    class="native-form"
    :class="props.theme"
  >
    <Transition @leave="onLeave">
      <div
        v-if="!isReady"
        class="loader"
      />
    </Transition>
  </div>
</template>

<script setup>
import { gsap } from 'gsap'
import { v4 as uuidv4 } from 'uuid'

// Composables
const { locale } = useI18n()
const { $hubspotForms } = useNuxtApp()
const { query } = useRoute()

// Emitter
const emit = defineEmits([
  'hubspot:form:ready',
  'hubspot:form:submit',
  'hubspot:form:submitted',
])

// Props
const props = defineProps({
  formId: {
    required: true,
    type: String,
  },
  theme: {
    default() {
      return 'light'
    },
    required: false,
    type: String,
    validator(value) {
      return ['dark', 'light'].indexOf(value) !== -1
    },
  },
  uniqueId: {
    required: true,
    type: String,
  },
})

// Refs
const $el = ref(null)
const isReady = ref(false)
const submissionId = ref(uuidv4())
const topCountries = ref([
  'United States',
  'France',
  'United Kingdom',
  'Canada',
  'Germany',
  'India',
  'Belgium',
  'Spain',
  'Australia',
  'Italy',
  'Switzerland',
  'South Africa',
  'Netherlands',
  'Sweden',
  'Singapore',
  'Denmark',
  'Philippines',
  'Malaysia',
  'Poland',
  'Brazil',
  'Mexico',
  'Ireland',
  'Portugal',
  'Norway',
  'Israel',
])

// Computed Porperties
const isDemoForm = computed(() => {
  return [
    'b1f081cb-6de5-424f-b2f9-5b7f56e237d3',
    '860d36ce-8c02-4b68-b0d2-4b03632d8f7d',
    '5c4f470d-280b-4686-998d-4b435bce1025',
  ].includes(props.formId)
})

// Methods
function addMandatoryClassOnInputAndSelectParent() {
  [
    ...$el.value.querySelectorAll('input[required], select[required]'),
  ].forEach((element) => {
    element.parentNode.parentNode.classList.add('mandatory')
  })
}

async function checkEmail(email) {
  const response = await fetch(
    `https://app.livestorm.co/api/v1/utils/email-check/?email=${email}`,
    {
      method: 'GET',
    },
  )

  const data = await response.json()

  if (data?.code === 200) {
    return data.body
  }

  return {
    success: false,
  }
}

function getAllHiddenFieldNames() {
  return [...$el.value.querySelectorAll('input[type="hidden"]')]
    .map(input => input.getAttribute('name'))
    .filter(name => name !== 'hs_context')
    .filter(name => name !== 'hs_custom_lifecycle_stage')
    .filter(name => name !== 'custom_lifecycle_stage')
    .filter(name => name !== 'gclid')
    .filter(name => name !== 'submission_id')
}

function onLeave($el, done) {
  gsap.to($el, {
    autoAlpha: 0,
    scale: 0,
    duration: 0.4,
    ease: 'expo.out',
  })
}

function onReady() {
  nextTick(() => {
    [...$el.value.querySelectorAll('fieldset')].forEach((fieldset) => {
      const { firstChild } = fieldset
      if (
        firstChild
        && firstChild.getAttribute('style') === 'display: none;'
      ) {
        fieldset.setAttribute('style', 'display: none !important;')
      }
    })
  })
  addMandatoryClassOnInputAndSelectParent()
  preSelectCountry()
  setHiddenInputValueFor('gclid', query.gclid)
  setHiddenInputValueFor('submission_id', submissionId.value)
  nextTick(() => {
    emit('hubspot:form:ready')
    gsap.to(
      $el.value.querySelector('form'),
      {
        autoAlpha: 1,
        height: 'auto',
        duration: 0.8,
        ease: 'expo.out',
      },
    )
  })
  $el.value.querySelector('form').addEventListener('submit', onValidationFailed)
}

function onSubmit(form) {
  const formData = new FormData(form)
  emit('hubspot:form:submit', formData)
}

async function onSubmitted(submittedValues) {
  emit('hubspot:form:submitted', {
    ...submittedValues,
    submission_id: submissionId.value,
  })

  if (isDemoForm.value) {
    // Check email validity
    const emailStatus = await checkEmail(submittedValues.email.toLowerCase())

    // Trigger Zap if it passes the validation
    if (emailStatus.success) {
      await triggerZap(submittedValues, emailStatus)
    }
  }
}

function onValidationFailed() {
  window.setTimeout(() => {
    [...document.querySelectorAll('.hs-form-field > .hs-error-msgs')]
      .map(el => el.parentNode.querySelector('label'))
      .filter(el => !el.classList.contains('error'))
      .forEach((el) => {
        el.classList.add('error')
      })
  }, 200)
}

function preSelectCountry() {
  const $select = $el.value.querySelector('select[name="country"]')
  if (!$select) {
    return
  }

  const language = (navigator.language || navigator.userLanguage)
    .split('-')
    .pop()
  const regionNames = new Intl.DisplayNames(['en'], {
    type: 'region',
  })
  const country = regionNames.of(language)
  if (!topCountries.value.includes(country)) {
    return
  }

  const index = [...$select.options].findIndex(
    ({ label }) => label === country,
  )

  if (index === -1) {
    return
  }

  $select.options[index].selected = true
  $select.dispatchEvent(new Event('change', { bubbles: true }))
}

function setHiddenInputValueFor(name, value) {
  if (!value) {
    return
  }
  const $input = $el.value.querySelector(`input[name="${name}"]`)
  $input?.setAttribute('value', value)
}

async function triggerZap(submittedValues, emailStatus) {
  await fetch('https://hooks.zapier.com/hooks/catch/8443099/byu398j', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    mode: 'no-cors',
    body: JSON.stringify({
      email: submittedValues.email.toLowerCase(),
      phone: submittedValues.phone,
      company: submittedValues.company,
      first_name: submittedValues.firstname,
      last_name: submittedValues.lastname,
      company_size: submittedValues.company_size,
      country: submittedValues.country,
      personal_email: emailStatus.free,
      utm_source: query['utm_source'] || undefined,
      utm_medium: query['utm_medium'] || undefined,
      utm_campaign: query['utm_campaign'] || undefined,
      utm_term: query['utm_term'] || undefined,
      utm_content: query['utm_content'] || undefined,
    }),
  })
}

// Lifecycle Hooks
onBeforeUnmount(() => {
  $el.value.querySelector('form')?.removeEventListener('submit', onValidationFailed)
})

onMounted(() => {
  $hubspotForms.create({
    callbacks: {
      onFormReady: onReady,
      onFormSubmit: onSubmit,
      onFormSubmitted: onSubmitted,
    },
    formId: props.formId,
    locale: locale.value,
    uniqueId: props.uniqueId,
  })
})

// Expose
defineExpose({
  getAllHiddenFieldNames,
  setHiddenInputValueFor,
})
</script>

<style lang="postcss" scoped>
.native-form {
  @apply flex justify-center w-full;

  &.dark {
    .loader {
      @apply border-l-white border-r-white border-t-white;
    }

    :deep(.hs-form) {
      fieldset {
        .hs-form-field {
          label:not(.hs-error-msg) {
            @apply text-winter-green-900 !important;
          }

          input,
          textarea {
            @apply bg-white border-grey-blue-300 text-winter-green-900 !important;

            &:disabled {
              @apply bg-grey-blue-100 border-grey-blue-200;
            }

            &:focus {
              @apply border-livestorm-blue-700;
            }

            &::placeholder {
              @apply text-grey-blue-600;
            }

            &[type="submit"] {
              @apply bg-livestorm-blue-700 text-white;

              &:hover {
                @apply bg-livestorm-blue-700;
              }
            }
          }

          select {
            @apply bg-transparent border-grey-blue-300 text-winter-green-900 !important;
            background-image: url("~/assets/svg/arrow-down-light.svg");

            &:focus {
              @apply border-livestorm-blue-700;
            }
          }
        }

        .legal-consent-container {
          p {
            @apply text-grey-blue-600;
          }

          .hs-form-booleancheckbox {
            @apply mt-0;

            label {
              @apply flex items-start !important;

              &::before {
                @apply bg-white border-grey-blue-500;
              }

              &:has(input:checked) {
                &::before {
                  @apply border-0 bg-winter-green-600 text-white !important;
                }
              }

              &.error {
                &::before {
                  @apply border-live-red-600;
                }
              }

              input[type="checkbox"] {
                @apply hidden;
              }

              input + span {
                @apply text-grey-blue-600 !important;

                a {
                  @apply text-winter-green-700 !important;
                }

                p,
                span {
                  @apply text-grey-blue-600 !important;

                  a {
                    @apply text-winter-green-700 !important;
                  }
                }
              }
            }
          }
        }
      }

      .hs-error-msgs {
        @apply bg-live-red-100 text-live-red-500;

        &::before {
          @apply bg-live-red-100;
        }
      }

      .hs-submit {
        input {
          @apply text-white bg-winter-green-700;

          &:hover {
            @apply bg-winter-green-800;
          }
        }
      }
    }
  }

  &.light {
    .loader {
      @apply border-l-grey-blue-600 border-r-grey-blue-600 border-t-grey-blue-600;
    }

    :deep(.hs-form) {
      fieldset {
        .hs-form-field {
          label:not(.hs-error-msg) {
            @apply text-winter-green-900 !important;
          }

          input,
          textarea {
            @apply bg-white border-grey-blue-300 text-winter-green-900 !important;

            &:disabled {
              @apply bg-grey-blue-100 border-grey-blue-200;
            }

            &:focus {
              @apply border-livestorm-blue-700;
            }

            &::placeholder {
              @apply text-grey-blue-600;
            }

            &[type="submit"] {
              @apply bg-livestorm-blue-700 text-white;

              &:hover {
                @apply bg-livestorm-blue-700;
              }
            }
          }

          select {
            @apply bg-transparent border-grey-blue-300 text-winter-green-900 !important;
            background-image: url("~/assets/svg/arrow-down-light.svg");

            &:focus {
              @apply border-livestorm-blue-700;
            }
          }
        }

        .legal-consent-container {
          p {
            @apply text-grey-blue-600;
          }

          .hs-form-booleancheckbox {
            @apply mt-0;

            label {
              @apply flex items-start !important;

              &::before {
                @apply bg-white border-grey-blue-500;
              }

              &:has(input:checked) {
                &::before {
                  @apply border-0 bg-livestorm-blue-600 text-white !important;
                }
              }

              &.error {
                &::before {
                  @apply border-live-red-600;
                }
              }

              input[type="checkbox"] {
                @apply hidden;
              }

              input + span {
                @apply text-grey-blue-600 !important;

                a {
                  @apply text-livestorm-blue-700 !important;
                }

                p,
                span {
                  @apply text-grey-blue-600 !important;

                  a {
                    @apply text-livestorm-blue-700 !important;
                  }
                }
              }
            }
          }
        }
      }

      .hs-error-msgs {
        @apply bg-live-red-100 text-live-red-500;

        &::before {
          @apply bg-live-red-100;
        }
      }

      .hs-submit {
        input {
          @apply text-white bg-livestorm-blue-700;

          &:hover {
            @apply bg-livestorm-blue-800;
          }
        }
      }
    }
  }

  .loader {
    @apply h-6 w-6 border-4 border-b-transparent rounded-[50%] animate-[loop_1s_infinite];
  }

  :deep(.hs-form) {
    @apply flex flex-row flex-wrap h-0 w-full opacity-0 invisible overflow-hidden;

    fieldset {
      @apply flex flex-col w-full max-w-full mt-6 !important;

      @screen md {
        @apply mt-8 !important;
      }

      &:first-child {
        @apply mt-0 !important;
      }

      @screen md {
        @apply mt-8 !important;
      }

      .hs-form-field {
        @apply w-full !important;

        label:not(.hs-error-msg) {
          @apply flex font-medium mb-1 text-sm leading-6 uppercase !important;
        }

        .input {
          @apply mr-0 !important;
        }

        input,
        textarea {
          @apply w-full h-10 px-4 text-sm leading-10 rounded border transition duration-300 ease-out !important;

          &.error,
          &.invalid {
            @apply border-red-600 !important;
          }

          &:focus {
            @apply outline-none;
          }

          &[type="submit"] {
            @apply border-0 cursor-pointer leading-[0];
          }
        }

        textarea {
          @apply h-30 py-3 leading-7 resize-none !important;
        }

        select {
          @apply w-full h-10 px-4 text-sm leading-10 rounded border appearance-none transition duration-300 ease-out !important;
          background-position: center right 1rem;
          background-repeat: no-repeat;
          background-size: 0.625rem;

          &.error,
          &.invalid {
            @apply border-red-600 !important;
          }

          &:focus {
            @apply outline-none;
          }
        }
      }

      .hs-form-field + .hs-form-field {
        @apply mt-6;
      }

      .legal-consent-container {
        > div + div {
          @apply mt-2;
        }

        .hs-form-booleancheckbox-display {
          @apply mb-0 !important;
        }

        p {
          @apply text-sm text-grey-blue-600;
        }

        .hs-form-field {
          @apply mb-0 !important;
        }

        label:not(.hs-error-msg) {
          @apply mb-0 !important;
        }

        .hs-richtext {
          @apply mt-4;

          &:first-child {
            @apply mt-0;
          }
        }

        .hs-form-booleancheckbox {
          @apply mt-0;

          label {
            @apply flex items-start !important;

            &::before {
              @apply flex flex-shrink-0 items-center justify-center w-4 h-4 bg-white border border-grey-blue-500 rounded-[4px] content-[''];
            }

            &:has(input:checked) {
              &::before {
                @apply border-0 bg-livestorm-blue-600 font-icon text-white text-xs content-["\e907"] !important;
              }
            }

            &.error {
              &::before {
                @apply border-live-red-600;
              }
            }

            input[type="checkbox"] {
              @apply hidden;
            }

            input + span {
              @apply ml-2 font-normal text-grey-blue-600 text-xs normal-case leading-4 !important;

              a {
                @apply text-livestorm-blue-700 !important;
              }

              p,
              span {
                @apply font-normal text-grey-blue-600 text-xs normal-case leading-4 !important;

                a {
                  @apply text-livestorm-blue-700 !important;
                }
              }
            }
          }
        }
      }
    }

    .hs-error-msgs {
      @apply relative flex flex-col justify-center mt-2 px-1 py-2 font-medium text-xs text-center leading-4 rounded-xs;

      &::before {
        @apply absolute left-1/2 -top-[2px] h-3 w-3 content-[''] transform -translate-x-1/2 rotate-45 -z-10;
      }
    }

    .hs_error_rollup {
      @apply hidden;
    }

    .hs-submit {
      @apply w-full mt-6;

      @screen md {
        @apply mt-8;
      }

      input {
        @apply w-full h-12 px-4 font-medium text-origin rounded cursor-pointer transition duration-300 ease-out;
      }
    }
  }

  :deep(.hs-main-font-element.submitted-message) {
    @apply hidden !important;
  }

  @media (min-width: 400px) {
    :deep(.hs-form) fieldset {
      @apply flex-row !important;
    }

    :deep(.hs-form) fieldset .hs-form-field + .hs-form-field {
      @apply mt-0 ml-8 !important;
    }
  }

  @keyframes loop {
    to{
      transform: rotate(.5turn)
    }
  }
}
</style>
