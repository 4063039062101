<template>
  <div
    class="hero"
    :class="[
      alignmentClass,
      props.colorScheme,
      { primary: props.level === 1 },
      { secondary: props.level === 2 },
      props.theme,
    ]"
  >
    <Component
      :is="atomTagOrMoleculeTagWithImage"
      v-if="hasTag || hasTagWithImage"
      v-bind="tagProps"
    >
      <slot name="tag" />
    </Component>
    <UiComponentHeading
      v-if="hasHeading"
      :as="as"
      :level="props.level"
      :theme="props.colorScheme"
    >
      <slot name="heading" />
    </UiComponentHeading>
    <div
      v-if="html"
      class="description"
      v-html="html"
    />
    <p
      v-else-if="hasParagraph"
      class="description"
    >
      <slot name="paragraph" />
    </p>
    <div
      v-if="haveButtons"
      class="button-list"
      :class="{ full: hasManyButtons }"
    >
      <AtomButton
        v-if="hasPrimaryButton"
        :action="(primaryButton.event && primaryButton.event.name) || primaryButton.action"
        :attributes="(primaryButton.event && primaryButton.event.attributes) || primaryButton.attributes"
        :class="primaryButton.class"
        :icon-position="primaryButton.iconPosition"
        :label="primaryButton.label"
        size="l"
        :to="primaryButton.to"
        @click="emit('primary:click', $event)"
      >
        {{ primaryButton.slot }}
        <UiComponentIcon
          v-if="primaryButton.icon"
          :type="primaryButton.icon"
        />
      </AtomButton>
      <AtomButton
        v-if="hasSecondaryButton"
        :action="(secondaryButton.event && secondaryButton.event.name) || secondaryButton.action"
        :attributes="(secondaryButton.event && secondaryButton.event.attributes) || secondaryButton.attributes"
        :label="secondaryButton.label"
        size="l"
        :to="secondaryButton.to"
        :type="secondaryButton.type ? secondaryButton.type : 'secondary'"
        @click="emit('secondary:click', $event)"
      >
        {{ secondaryButton.slot }}
        <UiComponentIcon
          v-if="secondaryButton.icon"
          :type="secondaryButton.icon"
        />
      </AtomButton>
      <AtomButton
        v-if="hasTertiaryButton"
        :action="(tertiaryButton.event && tertiaryButton.event.name) || tertiaryButton.action"
        :attributes="(tertiaryButton.event && tertiaryButton.event.attributes) || tertiaryButton.attributes"
        :label="tertiaryButton.label"
        size="l"
        :to="props.tertiaryButton.to"
        type="text"
        @click="emit('tertiary:click', $event)"
      >
        {{ tertiaryButton.slot }}
        <UiComponentIcon
          v-if="tertiaryButton.icon"
          :type="tertiaryButton.icon"
        />
      </AtomButton>
    </div>
    <ul
      v-if="featureList.length > 0"
      class="feature-list"
    >
      <AtomListItem
        v-for="(feature, index) in featureList"
        :key="index"
      >
        {{ feature }}
      </AtomListItem>
    </ul>
  </div>
</template>

<script setup>
// Emitter
const emit = defineEmits([
  'primary:click',
  'secondary:click',
  'tertiary:click',
])

// Props
const props = defineProps({
  alignment: {
    default() {
      return 'center'
    },
    required: false,
    type: String,
    validator(value) {
      return ['left', 'center'].indexOf(value) !== -1
    },
  },
  as: {
    default() {
      return 'heading'
    },
    required: false,
    type: String,
    validator(value) {
      return ['heading', 'paragraph'].indexOf(value) !== -1
    },
  },
  colorScheme: {
    default() {
      return 'light'
    },
    required: false,
    type: String,
    validator(value) {
      return ['dark', 'light'].indexOf(value) !== -1
    },
  },
  features: {
    default() {
      return []
    },
    required: false,
    type: Array,
  },
  html: {
    default() {
      return undefined
    },
    required: false,
    type: String,
  },
  level: {
    default() {
      return 1
    },
    required: false,
    type: Number,
    validator(value) {
      return [1, 2].indexOf(value) !== -1
    },
  },
  primaryButton: {
    default() {
      return {}
    },
    required: false,
    type: Object,
  },
  secondaryButton: {
    default() {
      return {}
    },
    required: false,
    type: Object,
  },
  tagWithImage: {
    default() {
      return {}
    },
    required: false,
    type: Object,
  },
  tertiaryButton: {
    default() {
      return {}
    },
    required: false,
    type: Object,
  },
  theme: {
    default() {
      return 'livestorm'
    },
    required: false,
    type: String,
    validator(value) {
      return ['black', 'livestorm', 'white'].indexOf(value) !== -1
    },
  },
})

// Slots
const slots = useSlots()

// Computed Properties
const alignmentClass = computed(() => {
  return `alignment-${props.alignment}`
})

const atomTagOrMoleculeTagWithImage = computed(() => {
  return hasTagWithImage.value ? resolveComponent('MoleculeTagWithImage') : resolveComponent('UiComponentTag')
})

const featureList = computed(() => {
  return props.features.filter(feature => !!feature)
})

const hasHeading = computed(() => {
  const nodes = slots && slots.heading && slots.heading()
  return nodes && nodes.length > 0
})

const hasManyButtons = computed(() => {
  return hasPrimaryButton.value && hasSecondaryButton.value
})

const hasParagraph = computed(() => {
  const nodes = slots && slots.paragraph && slots.paragraph()
  return nodes && nodes.length > 0
})

const hasPrimaryButton = computed(() => {
  return !!(props.primaryButton && props.primaryButton.slot)
})

const hasSecondaryButton = computed(() => {
  return !!(props.secondaryButton && props.secondaryButton.slot)
})

const hasTag = computed(() => {
  const nodes = slots && slots.tag && slots.tag()
  return nodes && nodes.length > 0 && findSlotContent(slots.tag()) !== null
})

const hasTagWithImage = computed(() => {
  return props.tagWithImage.icon && props.tagWithImage.color
})

const hasTertiaryButton = computed(() => {
  return !!(props.tertiaryButton && props.tertiaryButton.slot)
})

const haveButtons = computed(() => {
  return hasPrimaryButton.value || hasSecondaryButton.value || hasTertiaryButton.value
})

const tagProps = computed(() => {
  return {
    color: props.tagWithImage.color,
    icon: props.tagWithImage.icon,
    lazyload: false,
    to: props.tagWithImage.to,
    type: 'secondary',
  }
})

// Methods
function findSlotContent(nodes) {
  const [{ children }] = nodes
  if (Array.isArray(children)) {
    return findContent(children)
  }
  else {
    return children
  }
}
</script>

<style lang="postcss" scoped>
.hero {
  @apply flex flex-col items-center w-full;

  &.alignment-left.primary {
    @screen lg {
      @apply items-start;
    }

    .tag {
      @screen lg {
        @apply self-start;
      }
    }

    .tag-with-image {
      @apply font-bold;
    }

    .h1,
    .description {
      @screen lg {
        @apply text-left;
      }
    }

    .button-list,
    .feature-list {
      @screen lg {
        @apply justify-start;
      }

      .button,
      li {
        @screen lg {
          @apply self-start;
        }
      }
    }
  }

  &.alignment-left.secondary {
    @apply items-start;

    .tag {
      @apply self-start;
    }

    .h2,
    .description {
      @apply text-left;
    }

    .button-list,
    .feature-list {
      @apply justify-start;

      .button,
      li {
        @apply self-start;
      }
    }
  }

  &.black {
    .tag {
      @apply bg-grey-blue-200 text-grey-blue-900;
    }
  }

  &.white {
    .tag {
      @apply bg-grey-blue-800 text-white !important;
    }

    .h1,
    .h2,
    .description {
      @apply text-white !important;
    }
  }

  &.light {
    .description {
      @apply text-grey-blue-600;
    }
  }

  &.dark {
    .description {
      @apply text-white;
    }
  }

  .tag {
    @apply self-center;
  }

  .tag + .h1,
  .tag-with-image + .h1 {
    @apply mt-6;
  }

  .tag + .h2,
  .tag-with-image + .h1 {
    @apply mt-5;
  }

  .h1,
  .h2 {
    @apply text-center;

    @screen lg {
      @apply max-w-5xl;
    }
  }

  .description {
    @apply mt-4 text-lg text-center whitespace-pre-wrap leading-7;

    @screen lg {
      @apply max-w-3xl;
    }

    :deep(ul li) {
      @apply mt-4 flex items-start;

      &::before {
        @apply w-4 h-4 bg-livestorm-blue-200 rounded-full flex justify-center items-center bg-no-repeat mr-2 bg-center flex-shrink-0 mt-2;
        background-image: url("https://livestorm.imgix.net/1127/1615557777-check.png");
        background-size: 10px 8px;
        content: "";
      }
    }

    :deep(p + p) {
      @apply mt-4;
    }
  }

  .button-list {
    @apply flex flex-col w-full mt-6;

    @screen md {
      @apply flex-row justify-center items-center;
    }

    &.full {
      .button {
        @apply w-full;

        @screen md {
          @apply w-auto;
        }
      }
    }

    .button {
      @apply self-center;

      i {
        @apply ml-2;
      }

      &.text {
        @apply h-[50px] px-0;
      }
    }

    .button + .button {
      @apply mt-4;

      @screen md {
        @apply mt-0 ml-6;
      }
    }
  }

  .feature-list {
    @apply flex flex-col mt-6;

    @screen md {
      @apply flex-row justify-center items-center;
    }

    li {
      @apply flex items-center justify-center text-grey-blue-600;

      &:before {
        @apply bg-transparent font-semibold text-livestorm-blue-700 !important;
        font-size: 1.125rem;
      }
    }

    li + li {
      @apply mt-4;

      @screen md {
        @apply ml-4 mt-0;
      }
    }
  }
}
</style>
