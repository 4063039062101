import MarkdownIt from 'markdown-it'
import milr from 'markdown-it-replace-link'
import mila from 'markdown-it-link-attributes'
import { ObserveVisibility } from 'vue-observe-visibility'

export const useAnalyticsJS = () => {
  const { locale } = useI18n()

  return {
    page(name) {
      const { cookiesEnabledIds, isConsentGiven } = useCookieControl()
      window.analytics.page(name, {
        'Ads Storage Consent State': isConsentGiven.value ? cookiesEnabledIds.value.includes('advertising') : false,
        'Analytics Storage Consent State': isConsentGiven.value ? cookiesEnabledIds.value.includes('analytics') : false,
        'Ad User Data Consent State': isConsentGiven.value ? cookiesEnabledIds.value.includes('advertising') : false,
        'Ad Personalization Consent State': isConsentGiven.value ? cookiesEnabledIds.value.includes('advertising') : false,
        name,
        'path': window.location.pathname,
        'title': name,
        'url': window.location.href,
      })
    },
    async track(name, attributes) {
      window.analytics.track(name, {
        ...attributes,
        label: attributes.label || name,
        locale: locale.value,
        page: window.location.pathname,
        value: 1,
      })

      return await new Promise((resolve) => {
        window.setTimeout(() => {
          resolve()
        }, 300)
      })
    },
  }
}

export const useAvailableLanguages = () => {
  const allLanguages = ref([
    { name: 'English', slug: 'en' },
    { name: 'French', slug: 'fr' },
    { name: 'Spanish', slug: 'es' },
  ])

  const languages = useState('available-languages', () => [
    { name: 'English', slug: 'en' },
    { name: 'French', slug: 'fr' },
    { name: 'Spanish', slug: 'es' },
  ])

  function setAvailableLanguages(availableLanguages = ['en', 'es', 'fr']) {
    languages.value = allLanguages.value.filter(({ slug }) => availableLanguages.includes(slug))
  }

  return {
    languages,
    setAvailableLanguages,
  }
}

export const useBreadcrumb = () => {
  const listItems = useState('agency-form-is-visible', () => [])

  function updateListItems(list) {
    listItems.value = list
  }

  return {
    listItems,
    updateListItems,
  }
}

export const useCamelCase = () => {
  function camelCase(str) {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
  }

  return {
    camelCase,
  }
}

export const useChilipiper = () => {
  // Composables
  const { track } = useAnalyticsJS()
  const { locale } = useI18n()
  const { $chilipiper } = useNuxtApp()

  // Refs
  const lead = ref({})

  // Computed Properties
  const demoFormId = computed(() => {
    switch (locale.value) {
      case 'fr':
        return 'b1f081cb-6de5-424f-b2f9-5b7f56e237d3'
      case 'es':
        return '860d36ce-8c02-4b68-b0d2-4b03632d8f7d'
      default:
        return '5c4f470d-280b-4686-998d-4b435bce1025'
    }
  })

  const transformedLocale = computed(() => {
    switch (locale.value) {
      case 'fr':
        return 'fr_FR'
      default:
        return 'en_US'
    }
  })

  // Methods
  async function onMessage(event) {
    if (
      event.data.id === demoFormId.value
      && event.data.type === 'hsFormCallback'
      && event.data.eventName === 'onFormSubmitted'
    ) {
      lead.value = event.data.data.submissionValues
      $chilipiper.submit({
        lead: lead.value,
        locale: transformedLocale.value,
      })
      nextTick(() => {
        document.body.classList.add('no-scroll')
      })
    }

    if (
      Object.keys(lead.value).length > 0
      && event.data.action === 'booking-confirmed'
    ) {
      track('Demo Scheduled', {
        label: 'Demo Scheduled',
        category: 'Demo',
        booking_flow: 'routed',
        ...lead.value,
      })

      if (window.dataLayer) {
        const { v4: uuidv4 } = await import('uuid')
        dataLayer.push({ event: `event.demoScheduled.${uuidv4()}` })
      }
    }

    if (
      Object.keys(lead.value).length > 0
      && (event.data.action === 'close'
      || event.data.action === 'no-action-choice')
    ) {
      document.body.classList.remove('no-scroll')
    }
  }

  // Lifecycle hooks
  onBeforeUnmount(() => {
    window.removeEventListener('message', onMessage)
  })

  onMounted(() => {
    window.addEventListener('message', onMessage)
  })
}

export const useColorScheme = () => {
  const theme = useState('color-scheme', () => 'light')

  function useDarkMode() {
    theme.value = 'dark'
  }

  function useLightMode() {
    theme.value = 'light'
  }

  return {
    theme,
    useDarkMode,
    useLightMode,
  }
}

export const useConditionalPreviewMode = () => {
  const { query } = useRoute()
  const { public: publicRuntimeConfig } = useRuntimeConfig()
  return usePreviewMode({
    getState: () => {
      return {
        preview: true,
      }
    },
    shouldEnable: () => {
      return !!query.preview && publicRuntimeConfig.buildType !== 'production'
    },
  })
}

export const useCountryList = () => {
  const countryList = ref({
    'Afghanistan': 'AF',
    'Aland Islands': 'AX',
    'Albania': 'AL',
    'Algeria': 'DZ',
    'American Samoa': 'AS',
    'Andorra': 'AD',
    'Angola': 'AO',
    'Anguilla': 'AI',
    'Antarctica': 'AQ',
    'Antigua And Barbuda': 'AG',
    'Argentina': 'AR',
    'Armenia': 'AM',
    'Aruba': 'AW',
    'Australia': 'AU',
    'Austria': 'AT',
    'Azerbaijan': 'AZ',
    'Bahamas': 'BS',
    'Bahrain': 'BH',
    'Bangladesh': 'BD',
    'Barbados': 'BB',
    'Belarus': 'BY',
    'Belgium': 'BE',
    'Belize': 'BZ',
    'Benin': 'BJ',
    'Bermuda': 'BM',
    'Bhutan': 'BT',
    'Bolivia': 'BO',
    'Bosnia And Herzegovina': 'BA',
    'Botswana': 'BW',
    'Bouvet Island': 'BV',
    'Brazil': 'BR',
    'British Indian Ocean Territory': 'IO',
    'Brunei Darussalam': 'BN',
    'Bulgaria': 'BG',
    'Burkina Faso': 'BF',
    'Burundi': 'BI',
    'Cambodia': 'KH',
    'Cameroon': 'CM',
    'Canada': 'CA',
    'Cape Verde': 'CV',
    'Cayman Islands': 'KY',
    'Central African Republic': 'CF',
    'Chad': 'TD',
    'Chile': 'CL',
    'China': 'CN',
    'Christmas Island': 'CX',
    'Cocos (Keeling) Islands': 'CC',
    'Colombia': 'CO',
    'Comoros': 'KM',
    'Congo': 'CG',
    'Congo, Democratic Republic': 'CD',
    'Cook Islands': 'CK',
    'Costa Rica': 'CR',
    'Cote D\'Ivoire': 'CI',
    'Croatia': 'HR',
    'Cuba': 'CU',
    'Cyprus': 'CY',
    'Czech Republic': 'CZ',
    'Denmark': 'DK',
    'Djibouti': 'DJ',
    'Dominica': 'DM',
    'Dominican Republic': 'DO',
    'Ecuador': 'EC',
    'Egypt': 'EG',
    'El Salvador': 'SV',
    'Equatorial Guinea': 'GQ',
    'Eritrea': 'ER',
    'Estonia': 'EE',
    'Ethiopia': 'ET',
    'Falkland Islands (Malvinas)': 'FK',
    'Faroe Islands': 'FO',
    'Fiji': 'FJ',
    'Finland': 'FI',
    'France': 'FR',
    'French Guiana': 'GF',
    'French Polynesia': 'PF',
    'French Southern Territories': 'TF',
    'Gabon': 'GA',
    'Gambia': 'GM',
    'Georgia': 'GE',
    'Germany': 'DE',
    'Ghana': 'GH',
    'Gibraltar': 'GI',
    'Greece': 'GR',
    'Greenland': 'GL',
    'Grenada': 'GD',
    'Guadeloupe': 'GP',
    'Guam': 'GU',
    'Guatemala': 'GT',
    'Guernsey': 'GG',
    'Guinea': 'GN',
    'Guinea-Bissau': 'GW',
    'Guyana': 'GY',
    'Haiti': 'HT',
    'Heard Island & Mcdonald Islands': 'HM',
    'Holy See (Vatican City State)': 'VA',
    'Honduras': 'HN',
    'Hong Kong': 'HK',
    'Hungary': 'HU',
    'Iceland': 'IS',
    'India': 'IN',
    'Indonesia': 'ID',
    'Iran, Islamic Republic Of': 'IR',
    'Iraq': 'IQ',
    'Ireland': 'IE',
    'Isle Of Man': 'IM',
    'Israel': 'IL',
    'Italy': 'IT',
    'Jamaica': 'JM',
    'Japan': 'JP',
    'Jersey': 'JE',
    'Jordan': 'JO',
    'Kazakhstan': 'KZ',
    'Kenya': 'KE',
    'Kiribati': 'KI',
    'Korea': 'KR',
    'Kuwait': 'KW',
    'Kyrgyzstan': 'KG',
    'Lao People\'s Democratic Republic': 'LA',
    'Latvia': 'LV',
    'Lebanon': 'LB',
    'Lesotho': 'LS',
    'Liberia': 'LR',
    'Libyan Arab Jamahiriya': 'LY',
    'Liechtenstein': 'LI',
    'Lithuania': 'LT',
    'Luxembourg': 'LU',
    'Macao': 'MO',
    'Macedonia': 'MK',
    'Madagascar': 'MG',
    'Malawi': 'MW',
    'Malaysia': 'MY',
    'Maldives': 'MV',
    'Mali': 'ML',
    'Malta': 'MT',
    'Marshall Islands': 'MH',
    'Martinique': 'MQ',
    'Mauritania': 'MR',
    'Mauritius': 'MU',
    'Mayotte': 'YT',
    'Mexico': 'MX',
    'Micronesia, Federated States Of': 'FM',
    'Moldova': 'MD',
    'Monaco': 'MC',
    'Mongolia': 'MN',
    'Montenegro': 'ME',
    'Montserrat': 'MS',
    'Morocco': 'MA',
    'Mozambique': 'MZ',
    'Myanmar': 'MM',
    'Namibia': 'NA',
    'Nauru': 'NR',
    'Nepal': 'NP',
    'Netherlands': 'NL',
    'Netherlands Antilles': 'AN',
    'New Caledonia': 'NC',
    'New Zealand': 'NZ',
    'Nicaragua': 'NI',
    'Niger': 'NE',
    'Nigeria': 'NG',
    'Niue': 'NU',
    'Norfolk Island': 'NF',
    'Northern Mariana Islands': 'MP',
    'Norway': 'NO',
    'Oman': 'OM',
    'Pakistan': 'PK',
    'Palau': 'PW',
    'Palestinian Territory, Occupied': 'PS',
    'Panama': 'PA',
    'Papua New Guinea': 'PG',
    'Paraguay': 'PY',
    'Peru': 'PE',
    'Philippines': 'PH',
    'Pitcairn': 'PN',
    'Poland': 'PL',
    'Portugal': 'PT',
    'Puerto Rico': 'PR',
    'Qatar': 'QA',
    'Reunion': 'RE',
    'Romania': 'RO',
    'Russian Federation': 'RU',
    'Rwanda': 'RW',
    'Saint Barthelemy': 'BL',
    'Saint Helena': 'SH',
    'Saint Kitts And Nevis': 'KN',
    'Saint Lucia': 'LC',
    'Saint Martin': 'MF',
    'Saint Pierre And Miquelon': 'PM',
    'Saint Vincent And Grenadines': 'VC',
    'Samoa': 'WS',
    'San Marino': 'SM',
    'Sao Tome And Principe': 'ST',
    'Saudi Arabia': 'SA',
    'Senegal': 'SN',
    'Serbia': 'RS',
    'Seychelles': 'SC',
    'Sierra Leone': 'SL',
    'Singapore': 'SG',
    'Slovakia': 'SK',
    'Slovenia': 'SI',
    'Solomon Islands': 'SB',
    'Somalia': 'SO',
    'South Africa': 'ZA',
    'South Georgia And Sandwich Isl.': 'GS',
    'Spain': 'ES',
    'Sri Lanka': 'LK',
    'Sudan': 'SD',
    'Suriname': 'SR',
    'Svalbard And Jan Mayen': 'SJ',
    'Swaziland': 'SZ',
    'Sweden': 'SE',
    'Switzerland': 'CH',
    'Syrian Arab Republic': 'SY',
    'Taiwan': 'TW',
    'Tajikistan': 'TJ',
    'Tanzania': 'TZ',
    'Thailand': 'TH',
    'Timor-Leste': 'TL',
    'Togo': 'TG',
    'Tokelau': 'TK',
    'Tonga': 'TO',
    'Trinidad And Tobago': 'TT',
    'Tunisia': 'TN',
    'Turkey': 'TR',
    'Turkmenistan': 'TM',
    'Turks And Caicos Islands': 'TC',
    'Tuvalu': 'TV',
    'Uganda': 'UG',
    'Ukraine': 'UA',
    'United Arab Emirates': 'AE',
    'United Kingdom': 'GB',
    'United States': 'US',
    'United States Outlying Islands': 'UM',
    'Uruguay': 'UY',
    'Uzbekistan': 'UZ',
    'Vanuatu': 'VU',
    'Venezuela': 'VE',
    'Viet Nam': 'VN',
    'Virgin Islands, British': 'VG',
    'Virgin Islands, U.S.': 'VI',
    'Wallis And Futuna': 'WF',
    'Western Sahara': 'EH',
    'Yemen': 'YE',
    'Zambia': 'ZM',
    'Zimbabwe': 'ZW',
    'North Macedonia': 'MK',
    'Češka': 'CZ',
  })

  function getCountryCode(country) {
    return countryList.value[country]
  }

  return {
    getCountryCode,
  }
}

export const useDate = () => {
  const months = {
    en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    es: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    fr: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
  }

  const days = {
    en: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    es: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
    fr: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
  }

  return {
    months,
    days,
  }
}

export const useDebounce = () => {
  function debounce(func, timeout) {
    let timer
    return (...args) => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        func.apply(this, args)
      }, timeout)
    }
  }

  return {
    debounce,
  }
}

export const useEnhancedModal = () => {
  const isEnhanced = useState('modal-is-enhanced', () => false)

  return {
    isEnhanced,
  }
}

export const useForm = () => {
  const currentFormIndex = useState('form-index', () => -1)

  function setFormIndex(index) {
    currentFormIndex.value = index
  }

  return {
    currentFormIndex,
    setFormIndex,
  }
}

export const useIsLoggedIn = () => {
  // Refs
  const { hook } = useNuxtApp()
  const isLogged = useState('user-is-authenticated', () => false)

  // Methods
  function isUserLoggedIn() {
    return localStorage.ajs_user_id && localStorage.ajs_user_id !== null && localStorage.ajs_user_id !== 'null'
  }

  // Lifecycle Hooks
  hook('app:mounted', () => {
    isLogged.value = isUserLoggedIn()
  })

  hook('page:finish', () => {
    isLogged.value = isUserLoggedIn()
  })

  return {
    isLogged,
  }
}

export const useLdJson = (data) => {
  useHead(() => {
    return {
      script: [
        {
          type: 'application/ld+json',
          children: JSON.stringify(data, null, ''),
        },
      ],
    }
  })
}

export const useMarkdown = () => {
  const { public: publicRuntimeConfig } = useRuntimeConfig()
  const $md = new MarkdownIt('default', {
    breaks: true,
    html: true,
    linkify: false,
    replaceLink: function (link) {
      if (
        publicRuntimeConfig.appBaseUrl?.includes('livestorm.io')
        && link.includes('app.livestorm.co')
      ) {
        return link.replace('app.livestorm.co', publicRuntimeConfig.appBaseUrl)
      }
      return link
    },
  })

  $md.use(milr)
  $md.use(mila, [
    {
      attrs: {
        rel: 'noopener',
      },
      pattern: /https?:\/\/livestorm\.co/,
    },
    {
      attrs: {
        rel: 'noopener nofollow',
        target: '_blank',
      },
      pattern: /https?:\/\/app\.livestorm\.co/,
    },
    {
      attrs: {
        rel: 'noopener',
        target: '_blank',
      },
      pattern: /https?:\/\/[^.\s]+\.livestorm\.co/,
    },
    {
      attrs: {
        rel: 'noopener',
        target: '_blank',
      },
      pattern: /https?:\/\/video-engagement\.org/,
    },
    {
      attrs: {
        rel: 'noopener nofollow',
        target: '_blank',
      },
      pattern: /https?:\/\/[^.\s]+\.*/,
    },
    {
      attrs: {
        rel: 'noopener nofollow',
        target: '_blank',
      },
      pattern: /mailto:[^.\s]+/,
    },
    {
      attrs: {
        rel: 'noopener',
      },
    },
  ])

  return {
    $md,
  }
}

export const useNavigation = () => {
  const isVisible = useState('navigation-is-visible', () => true)

  function hide() {
    isVisible.value = false
  }

  function show() {
    isVisible.value = true
  }

  return {
    hide,
    isVisible,
    show,
  }
}

export const useResource = () => {
  const resource = useState('resource', () => {})

  function setResource(value) {
    resource.value = value
  }

  return {
    resource,
    setResource,
  }
}

export const useScreenSize = () => {
  const dpr = ref(1)
  const height = ref(640)
  const width = ref(360)

  function onResize() {
    height.value = Math.min(document.documentElement.clientHeight, window.innerHeight)
    width.value = Math.min(document.documentElement.clientWidth, window.innerWidth)
  }

  onBeforeUnmount(() => {
    window.removeEventListener('resize', onResize)
  })

  onMounted(() => {
    dpr.value = window.devicePixelRatio || 1
    window.addEventListener('resize', onResize)
    onResize()
  })

  return {
    dpr,
    height,
    width,
  }
}

// Directives
export const useVClickOutside = () => ({
  vClickOutside: {
    mounted: function (el, binding) {
      el.clickOutsideEvent = function (event) {
        if (el == event.target || el.contains(event.target)) {
          return
        }

        if (binding.value) {
          binding.value(event, el)
        }
      }
      document.addEventListener('click', el.clickOutsideEvent)
    },
    updated: function (el, binding) {
      document.removeEventListener('click', el.clickOutsideEvent)
      el.clickOutsideEvent = null
      el.clickOutsideEvent = function (event) {
        if (el == event.target || el.contains(event.target)) {
          return
        }

        if (binding.value) {
          binding.value(event, el)
        }
      }
      document.addEventListener('click', el.clickOutsideEvent)
    },
    unmounted: function (el) {
      document.removeEventListener('click', el.clickOutsideEvent)
    },
  },
})

export const useVObserveVisibility = () => ({
  vObserveVisibility: {
    beforeMount: (el, binding, vnode) => {
      vnode.context = binding.instance
      ObserveVisibility.bind(el, binding, vnode)
    },
    update: ObserveVisibility.update,
    unmounted: ObserveVisibility.unbind,
  },
})
