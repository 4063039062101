<template>
  <div
    class="tag"
    :class="[props.size, props.type]"
  >
    <slot />
  </div>
</template>

<script setup>
// Props
const props = defineProps({
  size: {
    default() {
      return 'm'
    },
    required: false,
    type: String,
    validator(value) {
      return ['s', 'm'].indexOf(value) !== -1
    },
  },
  type: {
    default() {
      return 'primary'
    },
    required: false,
    type: String,
    validator(value) {
      return ['primary', 'secondary'].indexOf(value) !== -1
    },
  },
})
</script>

<style lang="postcss" scoped>
.tag {
  @apply flex justify-center items-center font-semibold rounded-xs;

  &.s {
    @apply h-6 px-2 text-xs py-1 leading-4;
  }

  &.m {
    @apply h-8 px-4 text-sm;
  }

  &.primary {
    @apply bg-livestorm-blue-700 text-white;
  }

  &.secondary {
    @apply bg-livestorm-blue-100 text-livestorm-blue-700;
  }
}
</style>
