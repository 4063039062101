import revive_payload_client_e1oP5o6F3c from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.39_eslint@8.57.0_rollup@4.18.1_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_0QwAbkziSH from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.39_eslint@8.57.0_rollup@4.18.1_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_fitfWauxBZ from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.39_eslint@8.57.0_rollup@4.18.1_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_IyODIigklW from "/app/node_modules/.pnpm/nuxt-site-config@2.2.14_@nuxt+devtools@1.3.9_@vue+compiler-core@3.4.31_nuxt@3.12.3_postcss@8._w3b4koe6mjthokn73yjr77mrry/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_3H9Nnq8uSk from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.39_eslint@8.57.0_rollup@4.18.1_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_lcEmjfiuK9 from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.39_eslint@8.57.0_rollup@4.18.1_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ahIXNIetbt from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.39_eslint@8.57.0_rollup@4.18.1_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_HTKARRxZdH from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.39_eslint@8.57.0_rollup@4.18.1_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_yEP2Fyga00 from "/app/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.39_eslint@8.57.0_rollup@4.18.1_typescript@5.5.3_vite@5.3.3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_x3zNa5vUA4 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.18.1_vue@3.4.31/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_e1faO3MGrV from "/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.18.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_1vSFzGkL5o from "/app/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@6.5.3_rollup@4.18.1_webpack@5.93.0/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.mjs";
import _00_cookie_control_client_qHEDrmejEz from "/app/src/plugins/00.cookie-control.client.js";
import _00_helper_SxuAqYBJYi from "/app/src/plugins/00.helper.js";
import _01_gtm_client_UhL94TJLEV from "/app/src/plugins/01.gtm.client.js";
import _01_mutiny_client_bi3aincxJr from "/app/src/plugins/01.mutiny.client.js";
import _01_segment_client_7EZfffDfqM from "/app/src/plugins/01.segment.client.js";
import _02_chilipiper_client_S9Ag2Lcz0w from "/app/src/plugins/02.chilipiper.client.js";
import _02_hls_client_3CG9pBQltE from "/app/src/plugins/02.hls.client.js";
import _02_hubspot_forms_client_VXHXLQj2ZN from "/app/src/plugins/02.hubspot-forms.client.js";
import _02_hubspot_x_jquery_client_V0IHj2cyZf from "/app/src/plugins/02.hubspot-x-jquery.client.js";
import _02_lottie_player_client_UkKccL0wHJ from "/app/src/plugins/02.lottie-player.client.js";
import _02_partnerstack_client_9nj1sYfDni from "/app/src/plugins/02.partnerstack.client.js";
export default [
  revive_payload_client_e1oP5o6F3c,
  unhead_0QwAbkziSH,
  router_fitfWauxBZ,
  _0_siteConfig_IyODIigklW,
  payload_client_3H9Nnq8uSk,
  navigation_repaint_client_lcEmjfiuK9,
  check_outdated_build_client_ahIXNIetbt,
  chunk_reload_client_HTKARRxZdH,
  components_plugin_KR1HBZs4kY,
  prefetch_client_yEP2Fyga00,
  i18n_x3zNa5vUA4,
  plugin_e1faO3MGrV,
  plugin_1vSFzGkL5o,
  _00_cookie_control_client_qHEDrmejEz,
  _00_helper_SxuAqYBJYi,
  _01_gtm_client_UhL94TJLEV,
  _01_mutiny_client_bi3aincxJr,
  _01_segment_client_7EZfffDfqM,
  _02_chilipiper_client_S9Ag2Lcz0w,
  _02_hls_client_3CG9pBQltE,
  _02_hubspot_forms_client_VXHXLQj2ZN,
  _02_hubspot_x_jquery_client_V0IHj2cyZf,
  _02_lottie_player_client_UkKccL0wHJ,
  _02_partnerstack_client_9nj1sYfDni
]