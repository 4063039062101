export class OnDemand {
  constructor(src, config = {}) {
    this.isLoaded = false
    this.isLoading = false
    this.callbacks = []
    this.delay = config.delay || 1000
    this.src = src
    this.waitForPageLoad = config.waitForPageLoad || true
  }

  load(callback = () => {}) {
    if (this.isLoaded) return callback()

    this.callbacks.push(callback)

    if (!this.isLoading) {
      this.isLoading = true
      if (!this.waitForPageLoad || document.readyState === 'complete')
        this._loadScript()
      else window.addEventListener('load', () => this._loadScript())
    }
  }

  _loadScript() {
    window.setTimeout(() => {
      const script = document.createElement('script')
      script.async = true
      script.src = this.src
      script.onload = () => this._invokeCallbacks()
      document.getElementsByTagName('head')[0].appendChild(script)
    }, this.delay)
  }

  _invokeCallbacks() {
    this.isLoaded = true
    this.callbacks.forEach(callback => callback())
  }
}
