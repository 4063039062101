import { v4 as uuidv4 } from 'uuid'

class GoogleTagManager {
  constructor({ cookiesEnabledIds, device, id }) {
    this.buffer = []
    this.cookiesEnabledIds = cookiesEnabledIds
    this.device = device
    this.id = id
    this.initialized = false
    this.loaded = false
    this.loader = new OnDemand(`/scripts/${this.id.toLowerCase()}.js`)
  }

  consent() {
    if (this.cookiesEnabledIds.value?.includes('advertising')) {
      this.push({
        event: `consent.advertisingCookiesAccepted.${this.deviceType}`,
      })
      this.push({
        event: `consent.optional.advertisingCookiesAccepted.${this.deviceType}`,
      })
    }

    if (this.cookiesEnabledIds.value?.includes('analytics')) {
      this.push({
        event: `consent.analyticsCookiesAccepted.${this.deviceType}`,
      })
    }

    if (this.cookiesEnabledIds.value?.includes('live-chat')) {
      this.push({
        event: `consent.functionalityCookiesAccepted.${this.deviceType}`,
      })
    }

    if (this.cookiesEnabledIds.value?.includes('website-personalization')) {
      this.push({
        event: `consent.personalizationCookiesAccepted.${this.deviceType}`,
      })
    }
  }

  flush() {
    if (!(window.dataLayer || []).find(layer => layer.event === 'gtm.load')) {
      window.setTimeout(() => {
        this.flush()
      }, 100)
      return
    }

    this.buffer.forEach(obj => this.push(obj))
  }

  init() {
    this.initialized = true
    this.loader.load(() => {
      const noscript = document.createElement('noscript')
      const iframe = document.createElement('iframe')
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${this.id}`
      iframe.setAttribute('height', 0)
      iframe.setAttribute('width', 0)
      iframe.setAttribute('style', 'display:none;visibility:hidden')
      noscript.appendChild(iframe)
      document.body.appendChild(noscript)

      this.loaded = true
      this.flush()
    })
  }

  page() {
    if (this.cookiesEnabledIds.value?.includes('advertising')) {
      this.push({
        event: `allPages.advertisingCookiesAccepted.${uuidv4()}`,
      })
      this.push({
        event: `allPages.optional.advertisingCookiesAccepted.${uuidv4()}`,
      })
    }

    if (this.cookiesEnabledIds.value?.includes('analytics')) {
      this.push({
        event: `allPages.analyticsCookiesAccepted.${uuidv4()}`,
      })
    }

    if (this.cookiesEnabledIds.value?.includes('live-chat')) {
      this.push({
        event: `allPages.functionalityCookiesAccepted.${uuidv4()}`,
      })
    }

    if (this.cookiesEnabledIds.value?.includes('website-personalization')) {
      this.push({
        event: `allPages.personalizationCookiesAccepted.${uuidv4()}`,
      })
    }

    if (!this.initialized) {
      this.init()
    }
  }

  push(obj) {
    if (!this.loaded) {
      this.buffer.push(obj)
      return
    }

    if (!window.dataLayer) {
      window.dataLayer = []
    }

    window.dataLayer.push(obj)
  }

  get deviceType() {
    switch (true) {
      case this.device.isMobile:
        return 'mobile'
      case this.device.isTablet:
        return 'tablet'
      default:
        return 'desktop'
    }
  }
}

export default defineNuxtPlugin(() => {
  const { cookiesEnabledIds } = useCookieControl()
  const { $device } = useNuxtApp()
  const { public: publicRuntimeConfig } = useRuntimeConfig()
  const gtm = new GoogleTagManager({
    cookiesEnabledIds,
    device: $device,
    id: publicRuntimeConfig.gtmId,
  })

  return {
    provide: {
      gtm,
    },
  }
})
