export default defineNuxtPlugin(() => {
  const isLoading = useState('loader', () => false)
  const loader = new OnDemand('https://js.chilipiper.com/marketing.js', { delay: 0 })

  return {
    provide: {
      chilipiper: {
        submit(options) {
          loader.load(() => {
            window.ChiliPiper.submit('livestorm', 'inbound-router', {
              ...options,
              map: true,
              onError() {
                isLoading.value = false
              },
              onRouted() {
                isLoading.value = false
              },
            })
          })
        },
      },
    },
  }
})
