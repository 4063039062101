<template>
  <p :class="[props.size, props.theme]">
    <slot />
  </p>
</template>

<script setup>
// Props
const props = defineProps({
  size: {
    default() {
      return 'm'
    },
    required: false,
    type: String,
    validator(value) {
      return ['xs', 's', 'm'].indexOf(value) !== -1
    },
  },
  theme: {
    default() {
      return 'light'
    },
    required: false,
    type: String,
    validator(value) {
      return ['dark', 'light'].indexOf(value) !== -1
    },
  },
})
</script>

<style lang="postcss" scoped>
p {
  @apply font-normal whitespace-pre-line;

  &.dark {
    @apply text-[#CED0D0];
  }

  &.light {
    @apply text-grey-blue-600;
  }

  &.xs {
    @apply text-xs leading-4 font-medium;
  }

  &.s {
    @apply text-sm;
  }

  &.m {
    @apply text-origin leading-7;
  }
}
</style>
