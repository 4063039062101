<template>
  <div
    class="container"
    :class="[spanEnd, spanStart]"
  >
    <slot />
  </div>
</template>

<script setup>
// Props
const props = defineProps({
  end: {
    default() {
      return 0
    },
    required: false,
    type: Number,
  },
  start: {
    default() {
      return 0
    },
    required: false,
    type: Number,
  },
})

// Computed Properties
const spanEnd = computed(() => {
  return `span-end-${props.end}`
})

const spanStart = computed(() => {
  return `span-start-${props.start}`
})
</script>

<style lang="postcss" scoped>
.container {
  @apply flex w-full max-w-[1328px] px-4 box-border;

  @screen md {
    @apply px-6;
  }

  @screen xl {
    @apply px-6;
  }

  &.span-start-0 {
    @screen xl {
      @apply pl-6;
    }
  }

  &.span-start-1 {
    @screen xl {
      @apply pl-[134px];
    }
  }

  &.span-start-2 {
    @screen xl {
      @apply pl-[244px];
    }
  }

  &.span-start-3 {
    @screen xl {
      @apply pl-[354px];
    }
  }

  &.span-start-4 {
    @screen xl {
      @apply pl-[464px];
    }
  }

  &.span-end-0 {
    @screen xl {
      @apply pr-6;
    }
  }

  &.span-end-1 {
    @screen xl {
      @apply pr-[134px];
    }
  }

  &.span-end-2 {
    @screen xl {
      @apply pr-[244px];
    }
  }

  &.span-end-3 {
    @screen xl {
      @apply pr-[354px];
    }
  }

  &.span-end-4 {
    @screen xl {
      @apply pr-[464px];
    }
  }
}
</style>
