<template>
  <video
    ref="$el"
    :height="props.height"
    :loop="props.loop"
    :muted="props.muted"
    playsinline
    :poster="props.poster"
    :width="props.width"
  />
</template>

<script setup>
// Props
const props = defineProps({
  autoplay: {
    default() {
      return true
    },
    required: false,
    type: Boolean,
  },
  height: {
    required: true,
    type: Number,
  },
  loop: {
    default: undefined,
    required: false,
    type: Boolean,
  },
  muted: {
    default() {
      return true
    },
    required: false,
    type: Boolean,
  },
  poster: {
    default() {
      return undefined
    },
    required: false,
    type: String,
  },
  url: {
    required: true,
    type: String,
  },
  width: {
    required: true,
    type: Number,
  },
})

// Refs
const $el = ref(null)
const observer = ref(null)

// Methods
function observe() {
  observer.value = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting) {
      $el.value.src = props.url
      unobserve()
    }
  })
  observer.value.observe($el.value)
}

function unobserve() {
  observer.value.disconnect()
  observer.value = null
}

// Lifecycle Hooks
onBeforeUnmount(() => {
  if (observer.value) {
    unobserve()
  }
})

onMounted(() => {
  observe()

  if (props.autoplay) {
    $el.value.autoplay = true
  }
})
</script>

<style lang="postcss" scoped>
video {
  @apply w-full h-auto bg-grey-blue-100 object-cover rounded-4xl overflow-hidden;
}
</style>
