<template>
  <renderRoot />
</template>

<script setup>
// Composables
const slots = useSlots()

// Props
const props = defineProps({
  as: {
    default() {
      return 'heading'
    },
    required: false,
    type: String,
    validator(value) {
      return ['heading', 'paragraph'].indexOf(value) !== -1
    },
  },
  level: {
    default() {
      return 1
    },
    required: false,
    type: Number,
    validator(value) {
      return [1, 2, 3, 4, 5, 6].indexOf(value) !== -1
    },
  },
  theme: {
    default() {
      return 'light'
    },
    required: false,
    type: String,
    validator(value) {
      return ['dark', 'light'].indexOf(value) !== -1
    },
  },
})

// Refs
const $el = ref()

// Computed Properties
const is = computed(() => {
  if (props.as === 'heading') {
    return `h${props.level}`
  }
  else {
    return 'p'
  }
})

const text = computed(() => {
  const content = findContent(slots.default() || [{ children: '' }])
  return content
    .replace(
      /--(.*?)--/gm,
      '<u>$1</u>',
    )
    .replace(
      /\*(.*?)\*/gm,
      '<strong>$1</strong>',
    )
})

// Methods
function findContent(nodes) {
  const [{ children }] = nodes
  if (Array.isArray(children)) {
    return findContent(children)
  }
  else {
    return children
  }
}

function renderRoot() {
  return h(is.value, {
    class: [`h${props.level}`, props.theme],
    innerHTML: text.value,
    ref: $el,
  })
}

// Expose
defineExpose({
  $el,
})
</script>

<style lang="postcss" scoped>
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @apply font-sans whitespace-pre-line;

  &.dark {
    @apply text-white;

    :deep(b),
    :deep(strong) {
      @apply text-white;
    }
  }

  &.light {
    @apply text-winter-green-900;
  }
}

.h1,
.h2 {
  @apply font-normal;

  :deep(b),
  :deep(strong) {
    @apply font-normal text-livestorm-blue-700;
  }
}

.h3,
.h4,
.h5,
.h6 {
  @apply font-medium;

  :deep(b),
  :deep(strong) {
    @apply font-medium;
  }

  :deep(strong) {
    @apply font-medium text-livestorm-blue-700;
  }
}

.h1 {
  @apply text-4xl leading-[56px];

  @screen mdminus {
    @apply text-5xl leading-[64px];
  }

  @screen mdplus {
    @apply text-6xl leading-[80px];
  }
}

.h2 {
  @apply text-3xl leading-10;

  @screen mdminus {
    @apply font-medium text-3xl leading-10;
  }

  @screen mdplus {
    @apply font-normal text-5xl leading-[64px];
  }

  :deep(b),
  :deep(strong) {
    @screen mdminus {
      @apply font-medium;
    }

    @screen mdplus {
      @apply font-normal;
    }
  }
}

.h3 {
  @apply text-2xl leading-8;

  @screen mdminus {
    @apply text-[28px] leading-[34px];
  }

  @screen mdplus {
    @apply text-3xl leading-10;
  }
}

.h4 {
  @apply text-xl leading-7;

  @screen mdminus {
    @apply text-2xl leading-8;
  }

  @screen mdplus {
    @apply text-2xl leading-8;
  }
}

.h5 {
  @apply text-origin leading-7;

  @screen mdminus {
    @apply text-xl;
  }

  @screen mdplus {
    @apply text-xl;
  }
}

.h6 {
  @apply text-origin leading-7;
}
</style>
