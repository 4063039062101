<template>
  <NuxtLink
    v-if="is === 'nuxt-link'"
    ref="$el"
    class="button"
    :class="[{ disabled }, iconPosition, size, theme, type]"
    tabindex="0"
    v-bind="params"
  >
    <slot />
  </NuxtLink>
  <button
    v-else-if="is === 'button'"
    ref="$el"
    class="button"
    :class="[{ disabled }, iconPosition, size, theme, type]"
    tabindex="0"
    type="button"
    v-bind="params"
  >
    <slot />
  </button>
  <a
    v-else
    ref="$el"
    class="button"
    :class="[{ disabled }, iconPosition, size, theme, type]"
    tabindex="0"
    v-bind="params"
  ><slot /></a>
</template>

<script setup>
// Props
const props = defineProps({
  disabled: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  download: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  forceNoFollow: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  forceNewTab: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  iconPosition: {
    default() {
      return 'icon-right'
    },
    required: false,
    type: String,
    validator(value) {
      return ['icon-left', 'icon-right'].indexOf(value) !== -1
    },
  },
  size: {
    default() {
      return 'm'
    },
    required: false,
    type: String,
    validator(value) {
      return ['m', 'l'].indexOf(value) !== -1
    },
  },
  theme: {
    default() {
      return 'light'
    },
    required: false,
    type: String,
    validator(value) {
      return ['dark', 'light'].indexOf(value) !== -1
    },
  },
  type: {
    default() {
      return 'primary'
    },
    required: false,
    type: String,
    validator(value) {
      return (
        ['primary', 'secondary', 'text', 'no-style'].indexOf(value) !== -1
      )
    },
  },
  to: {
    default() {
      return ''
    },
    required: false,
    type: String,
  },
})

// Ref
const $el = ref(null)

// Computed Properties
const is = computed(() => {
  if (props.to === '' || props.to.charAt(0) === '#') {
    return 'button'
  }
  return isURL(props.to) ? 'a' : 'nuxt-link'
})

const params = computed(() => {
  if (isURL(props.to)) {
    return {
      download: props.download,
      href: props.to,
      rel: 'noopener nofollow',
      target: '_blank',
    }
  }
  else if (props.to.charAt(0) === '/') {
    return {
      to: props.to,
      target: props.forceNewTab ? '_blank' : undefined,
    }
  }
  return {}
})

// Methods
function isURL(to) {
  const pattern
    = /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi
  return pattern.test(to)
}
</script>

<style lang="postcss" scoped>
.button {
  @apply rounded flex items-center justify-center font-medium leading-none cursor-pointer transition duration-300 ease-out;

  &.disabled {
    @apply opacity-40 cursor-not-allowed;
  }

  &:focus {
    @apply outline-none;
  }

  &:hover {
    :deep(i) {
      &.icon-arrow-right {
        @apply translate-x-2;
      }
    }
  }

  &.m {
    @apply h-10 px-3 text-origin;
  }

  &.l {
    @apply h-12 px-6 text-origin;
  }

  &.dark {
    &.primary {
      @apply text-white;

      @apply bg-winter-green-700;

      &.disabled {
        &:hover {
          @apply bg-winter-green-700;
        }
      }

      &:hover {
        @apply bg-winter-green-800;
      }
    }

    &.text {
      @apply bg-transparent text-winter-green-900;
    }
  }

  &.light {
    &.primary {
      @apply text-white;

      @apply bg-livestorm-blue-700;

      &:hover {
        @apply bg-livestorm-blue-800;
      }

      :deep(i) {
        @apply text-white;
      }
    }

    &.text {
      @apply bg-transparent text-grey-blue-900;
    }
  }

  &.secondary {
    @apply bg-grey-blue-200 text-winter-green-900;

    &:hover {
      @apply bg-grey-blue-300;
    }
  }

  &.text {
    @apply h-[50px] px-0 font-bold;

    :deep(i) {
      @apply font-semibold;
    }
  }

  &.no-style {
    @apply px-0 h-auto;
  }

  &.icon-left {
    @apply flex-row-reverse;

    :deep(i) {
      @apply ml-0 mr-2 !important;
    }
  }

  :deep(i) {
    &.icon-arrow-right {
      @apply ml-2 transform transition duration-300 ease-out;
    }
  }
}
</style>
