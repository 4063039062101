<template>
  <div
    ref="$el"
    class="ebook"
    :class="orientation"
    :style="style"
  >
    <div class="back-cover" />
    <div class="sheet" />
    <div
      class="sheet"
      :style="{ bottom: '-4px' }"
    />
    <div
      class="sheet"
      :style="{ bottom: '-2px' }"
    />
    <UiComponentImage
      v-if="orientation === 'landscape'"
      :alt="image.alt"
      class="front-cover"
      :expected-size="props.size || imageWidth"
      :height="image.height"
      :lazyload="props.lazyload"
      :source="image.url"
      :width="image.width"
    />
    <UiComponentImage
      v-else
      :alt="image.alt"
      class="front-cover"
      :expected-size="props.size || imageWidth"
      :height="image.height"
      :lazyload="props.lazyload"
      orientation="portrait"
      :source="image.url"
      :width="image.width"
    />
    <div class="gradient" />
  </div>
</template>

<script setup>
// Props
const props = defineProps({
  image: {
    required: true,
    type: Object,
  },
  lazyload: {
    default() {
      return true
    },
    required: false,
    type: Boolean,
  },
  orientation: {
    default() {
      return 'landscape'
    },
    required: false,
    type: String,
    validator(value) {
      return ['landscape', 'portrait'].indexOf(value) !== -1
    },
  },
  size: {
    default() {
      return null
    },
    required: false,
    type: Number,
  },
})

// Refs
const $el = ref(null)
const imageWidth = ref(278)
const resizeObserver = ref(null)

// Computed Properties
const style = computed(() => {
  if (!props.size) {
    return {}
  }

  return props.orientation === 'portrait'
    ? { maxHeight: `${props.size}px` }
    : { maxWidth: `${props.size}px` }
})

// Methods
function onObserve() {
  imageWidth.value = $el.value.offsetWidth
}

// Lifecycle Hooks
onBeforeUnmount(() => {
  resizeObserver.value.unobserve($el.value)
})

onMounted(() => {
  resizeObserver.value = new ResizeObserver(onObserve)
  resizeObserver.value.observe($el.value)
})
</script>

<style lang="postcss" scoped>
.ebook {
  @apply relative flex;
  border-radius: 8px 16px 16px 8px;

  .back-cover,
  .front-cover,
  .sheet {
    @apply bg-white;
    border-radius: 8px 16px 16px 8px;
  }

  .back-cover,
  .gradient,
  .sheet {
    @apply absolute h-full;
  }

  .back-cover {
    @apply w-full;
    bottom: -10px;
  }

  .sheet {
    width: 97%;
    bottom: -6px;
    left: 1%;
    box-shadow: 0 2px 4px 0 rgb(13 22 38 / 7%);
  }

  .gradient {
    width: 4.4%;
    background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
      linear-gradient(
        270deg,
        rgba(255, 255, 255, 0.23) 13.62%,
        rgba(0, 0, 0, 0.08) 55.91%,
        rgba(255, 255, 255, 0) 100%
      );
    border-radius: 8px 0 0 8px;
    mix-blend-mode: multiply;
  }
}
</style>
