<template>
  <div
    class="wrapper"
    @click="emit('wrapper:click')"
  >
    <slot />
  </div>
</template>

<script setup>
// Emitter
const emit = defineEmits([
  'wrapper:click',
])
</script>

<style lang="postcss" scoped>
.wrapper {
  @apply flex w-full max-w-[100vw] box-border;
}
</style>
