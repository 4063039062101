export class Detector {
  async detect() {
    try {
      const response = await fetch('https://cdn.segment.com/analytics.js/v1', {
        method: 'HEAD',
        mode: 'no-cors',
      })
      return response.redirected
    }
    catch {
      return true
    }
  }
}
