export default defineNuxtPlugin(() => {
  const loader = new OnDemand('https://js.hsforms.net/forms/embed/v2.js', { delay: 0 })

  return {
    provide: {
      hubspotForms: {
        create({ callbacks, formId, locale, uniqueId }) {
          loader.load(() => {
            window.hbspt.forms.create({
              formId: formId,
              locale: locale,
              portalId: '9228665',
              region: 'na1',
              target: `#${uniqueId}`,
              formInstanceId: uniqueId,
              onFormReady: callbacks.onFormReady,
              onFormSubmit: callbacks.onFormSubmit,
              onFormSubmitted: (_, { submissionValues }) => {
                callbacks.onFormSubmitted(submissionValues)
              },
            })
          })
        },
      },
    },
  }
})
