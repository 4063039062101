<template>
  <div
    ref="$el"
    class="modal"
    :class="props.theme"
    :style="style"
  >
    <slot name="custom-close" />
    <div
      class="background"
      @click="close"
    />
    <div class="box">
      <div
        v-if="props.hasCloseButton"
        class="close"
        @click="close"
      >
        <UiComponentIcon
          type="x"
        />
      </div>
      <div
        v-if="hasHeadSection"
        class="head"
      >
        <UiComponentHeading
          v-if="hasTitle"
          as="paragraph"
          :level="4"
        >
          <slot name="title" />
        </UiComponentHeading>
        <UiComponentParagraph
          v-if="hasDescription"
          class="description"
        >
          <slot name="description" />
        </UiComponentParagraph>
      </div>
      <div class="body">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script setup>
// Composables
const { height: screenHeight } = useScreenSize()
const slots = useSlots()

// Emitter
const emit = defineEmits([
  'modal:close',
])

// Props
const props = defineProps({
  hasCloseButton: {
    default() {
      return true
    },
    required: false,
    type: Boolean,
  },
  theme: {
    default() {
      return 'light'
    },
    required: false,
    type: String,
    validator(value) {
      return ['dark', 'light'].indexOf(value) !== -1
    },
  },
})

// Refs
const $el = ref(null)

// Computed Properties
const hasDescription = computed(() => {
  return !!(slots && slots.description && slots.description() && slots.description().length > 0)
})

const hasTitle = computed(() => {
  return !!(slots && slots.title && slots.title() && slots.title().length > 0)
})

const hasHeadSection = computed(() => {
  return hasTitle.value || hasDescription.value
})

const style = computed(() => {
  return {
    '--vh': `${screenHeight.value * 0.01}px`,
  }
})

// Methods
function close() {
  emit('modal:close')
}

// Expose
defineExpose({
  $el,
})
</script>

<style lang="postcss" scoped>
.modal {
  @apply fixed inset-0 flex h-full w-screen py-8 overflow-y-scroll z-50 transition-none;
  min-height: -webkit-fill-available;
  max-height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100);

  &.dark {
    .background {
      @apply bg-white/10;
    }

    .box {
      .head {
        .h4 {
          :deep(strong) {
            @apply text-winter-green-700;
          }
        }
      }
    }
  }

  &.light {
    .background {
      @apply bg-black/10;
    }
  }

  .background {
    @apply fixed inset-0 h-full w-full transition-none;
  }

  .box {
    @apply relative flex flex-col w-full m-auto p-8 bg-white rounded-4xl shadow-xl z-0 transition-none;

    @screen md {
      @apply max-w-[720px] p-12;
    }

    @screen lg {
      @apply max-w-[588px];
    }

    .close {
      @apply absolute right-[6px] top-[6px] flex items-center justify-center h-12 w-12 cursor-pointer transform rotate-0 transition-transform duration-500 ease-in-out z-20;

      @screen md {
        @apply right-[14px] top-[14px];
      }

      &:hover {
        @apply rotate-90;
      }
    }

    .head {
      @apply flex flex-col;

      .h4 {
        @apply font-medium text-xl leading-7;

        @screen mdminus {
          @apply text-2xl leading-8;
        }

        @screen mdplus {
          @apply text-2xl leading-8;
        }

        :deep(strong) {
          @apply font-medium;
        }
      }

      .h4 + .description {
        @apply mt-4;
      }

      .description {
        @apply text-sm text-grey-blue-600 leading-6;
      }
    }

    .body {
      @apply flex flex-col items-center;
    }

    .head + .body {
      @apply mt-6;
    }
  }
}
</style>
