import en from './content/app/index/en.json'
import es from './content/app/index/es.json'
import fr from './content/app/index/fr.json'

export default defineAppConfig({
  chilipiper: {
    loader: {
      wording: {
        en: en.componentGlobal.chilipiperLoaderWording,
        es: es.componentGlobal.chilipiperLoaderWording,
        fr: fr.componentGlobal.chilipiperLoaderWording,
      },
    },
  },
})
