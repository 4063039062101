export default defineNuxtPlugin(() => {
  return {
    provide: {
      changePage: (link, optionsParams = {}) => {
        const options = {
          internal: false,
          newTab: false,
          ...optionsParams,
        }

        const targetUrl = options.internal ? app.localePath({ name: link }) : link

        if (options.newTab) {
          const a = document.createElement('a')
          a.href = targetUrl
          a.rel = 'noopener'
          a.target = '_blank'

          a.click()
        }
        else if (import.meta.client) {
          window.location.href = targetUrl
        }
      },
      interpolate: (text, changeStrings) => {
        Object.keys(changeStrings).forEach((key) => {
          text = text.replace(new RegExp(key, 'g'), changeStrings[key])
        })
        return text
      },
      slugify: (string) => {
        string = string.replace(/^\s+|\s+$/g, '').toLowerCase()

        const from
          = 'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;'
        const to
          = 'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------'
        for (var i = 0, l = from.length; i < l; i++) {
          string = string.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        }

        return string
          .replace(/[^a-z0-9 -]/g, '')
          .replace(/\s+/g, '-')
          .replace(/-+/g, '-')
      },
    },
  }
})
