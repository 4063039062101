const getDefaultLocale = ($i18n) => {
  return ['en', 'es', 'fr'].includes($i18n.getBrowserLocale())
    ? $i18n.getBrowserLocale()
    : 'en'
}

export default defineNuxtRouteMiddleware(async ({ name, params }) => {
  const { isCrawler } = useDevice()

  if (isCrawler) {
    return
  }

  const { $i18n, isHydrating, payload } = useNuxtApp()

  if (import.meta.client && isHydrating && payload.serverRendered) {
    const expires = new Date()
    expires.setDate(expires.getDate() + 365)

    const experiences = useCookie(
      'experiences',
      {
        default: () => [],
        expires,
        path: '/',
      },
    )

    if (experiences.value.length === 0 || !experiences.value.map(({ experience }) => experience).includes('pricing')) {
      experiences.value.push({
        experience: 'pricing',
        variation: Math.random() <= 0.5 ? 'control' : 'test',
      })
    }

    const preferences = useCookie(
      'preferences',
      {
        default: () => ({
          locale: getDefaultLocale($i18n),
        }),
        expires,
        path: '/',
      },
    )
    const locale = name.substr(-2)

    if (locale !== preferences.value.locale) {
      if (name.includes('slug___')) {
        const slugIsArray = Array.isArray(params.slug)
        const slug = slugIsArray ? params.slug.join('-') : params.slug
        const path = `page-${name.substring(0, name.length - 5).replace('slug', '[slug]')}-${slug}-${locale}`
        if (payload.data[path]) {
          const cache = payload.data[path]
          if (cache.page) {
            const alternativeVersion = (cache.page.alternativeVersions || []).find(version => version.locale === preferences.value.locale)
            if (alternativeVersion) {
              const localeRoute = useLocaleRoute()
              const route = localeRoute(name.substring(0, name.length - 5), preferences.value.locale)
              if (route) {
                const href = slugIsArray ? `${route.fullPath}/${alternativeVersion.value}` : route.fullPath.replace(slug, alternativeVersion.value || alternativeVersion.slug)
                window.location.href = href
              }
            }
          }
        }
      }
      else {
        const localeRoute = useLocaleRoute()
        const route = localeRoute(name.substring(0, name.length - 5), preferences.value.locale)
        if (route) {
          window.location.href = route.fullPath
        }
      }
    }
  }
})
