<template>
  <i
    v-if="unicode"
    class="icon"
    :class="[className, props.theme]"
    :data-unicode="unicode"
  />
</template>

<script setup>
// Props
const props = defineProps({
  theme: {
    default() {
      return 'light'
    },
    required: false,
    type: String,
    validator(value) {
      return ['dark', 'light'].indexOf(value) !== -1
    },
  },
  type: {
    required: true,
    type: String,
    validator(value) {
      const isValid = [
        'activity-fill',
        'ai',
        'arrow-left',
        'arrow-right',
        'background',
        'book',
        'calendar',
        'check',
        'chevron-down',
        'chevron-left',
        'chevron-right',
        'chevron-up',
        'code',
        'copy',
        'cta',
        'custom-design',
        'customer-success',
        'download',
        'edit',
        'empty-star',
        'event-management',
        'exit-fullscreen',
        'external-link',
        'facebook',
        'file',
        'filter',
        'finance-2',
        'glassdoor',
        'globe',
        'go-fullscreen',
        'graduation-cap',
        'grid',
        'healthcare',
        'heart',
        'help-circle',
        'info',
        'instagram',
        'library',
        'life-buoy',
        'light',
        'link',
        'linkedin',
        'list',
        'lock',
        'mail',
        'medias',
        'menu',
        'message-circle',
        'minus',
        'office',
        'on-demand',
        'onestream',
        'package',
        'pause',
        'percent',
        'phone',
        'pie-chart',
        'pigtail-arrow',
        'play',
        'plus',
        'poll',
        'product-demo',
        'radio',
        'reactions',
        'refresh-cw',
        'registration-page',
        'restreaming',
        'room-camera',
        'room-lock',
        'room-mic-off',
        'room-mic',
        'room-record',
        'screen',
        'screensharing',
        'search',
        'settings',
        'share-2',
        'share',
        'shield',
        'shopping-bag',
        'square',
        'star-2',
        'star',
        'target',
        'thumb-down',
        'thumb-up',
        'timer',
        'tool',
        'trending-up',
        'twitter',
        'user-check',
        'user-plus',
        'user',
        'users',
        'volume-1',
        'volume-2',
        'volume-x',
        'x',
        'youtube',
        'zap',
        'zoom-plus',
      ].indexOf(value) !== -1
      if (!isValid) {
        console.log(value)
      }
      return isValid
    },
  },
})

// Ref
const icons = ref([
  'activity-fill',
  'ai',
  'arrow-left',
  'arrow-right',
  'background',
  'book',
  'calendar',
  'check',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'code',
  'copy',
  'cta',
  'custom-design',
  'customer-success',
  'download',
  'edit',
  'empty-star',
  'event-management',
  'exit-fullscreen',
  'external-link',
  'facebook',
  'file',
  'filter',
  'finance-2',
  'glassdoor',
  'globe',
  'go-fullscreen',
  'graduation-cap',
  'grid',
  'healthcare',
  'heart',
  'help-circle',
  'info',
  'instagram',
  'library',
  'life-buoy',
  'light',
  'link',
  'linkedin',
  'list',
  'lock',
  'mail',
  'medias',
  'menu',
  'message-circle',
  'minus',
  'office',
  'on-demand',
  'onestream',
  'package',
  'pause',
  'percent',
  'phone',
  'pie-chart',
  'pigtail-arrow',
  'play',
  'plus',
  'poll',
  'product-demo',
  'radio',
  'reactions',
  'refresh-cw',
  'registration-page',
  'restreaming',
  'room-camera',
  'room-lock',
  'room-mic-off',
  'room-mic',
  'room-record',
  'screen',
  'screensharing',
  'search',
  'settings',
  'share-2',
  'share',
  'shield',
  'shopping-bag',
  'square',
  'star-2',
  'star',
  'target',
  'thumb-down',
  'thumb-up',
  'timer',
  'tool',
  'trending-up',
  'twitter',
  'user-check',
  'user-plus',
  'user',
  'users',
  'volume-1',
  'volume-2',
  'volume-x',
  'x',
  'youtube',
  'zap',
  'zoom-plus',
])

// Computed Properties
const className = computed(() => {
  return `icon-${props.type}`
})

const unicode = computed(() => {
  const index = icons.value.findIndex(name => props.type === name)

  if (index === -1) {
    return null
  }

  return String.fromCharCode(parseInt('0xE900', 16) + index)
})
</script>

<style lang="postcss" scoped>
.icon {
  @apply antialiased font-normal font-icon not-italic leading-none !important;

  &::before {
    @apply content-[attr(data-unicode)];
  }

  &.dark {
    @apply text-white;
  }

  &.light {
    @apply text-winter-green-900;
  }
}
</style>
