<template>
  <NuxtLink
    v-if="is === 'link'"
    class="tag-with-image"
    :class="[color, size]"
    :to="to"
  >
    <UiComponentIcon
      v-if="icon"
      :type="icon.name"
    />
    <UiComponentImage
      v-if="image"
      :alt="image.alt"
      :expected-size="imageSize"
      :height="image.height"
      :lazyload="lazyload"
      :source="image.url"
      :width="image.width"
    />
    <slot />
  </NuxtLink>
  <div
    v-else-if="is === 'div'"
    class="tag-with-image"
    :class="[color, size]"
  >
    <UiComponentIcon
      v-if="icon"
      :type="icon.name"
    />
    <UiComponentImage
      v-if="image"
      :alt="image.alt"
      :expected-size="imageSize"
      :height="image.height"
      :lazyload="lazyload"
      :source="image.url"
      :width="image.width"
    />
    <slot />
  </div>
</template>

<script setup>
// Props
const props = defineProps({
  color: {
    type: String,
    required: true,
    validator(value) {
      return (
        [
          'carmin',
          'green',
          'grey-blue',
          'livestorm-blue',
          'pale-blue',
          'pale-red',
          'skin-tone',
        ].indexOf(value) !== -1
      )
    },
  },
  icon: {
    default: null,
    type: Object,
    required: false,
  },
  image: {
    default: null,
    type: Object,
    required: false,
  },
  lazyload: {
    default() {
      return true
    },
    required: false,
    type: Boolean,
  },
  size: {
    default() {
      return 'm'
    },
    type: String,
    validator(value) {
      return ['s', 'm'].indexOf(value) !== -1
    },
  },
  to: {
    default() {
      return null
    },
    required: false,
    type: String,
  },
})

// Computed Properties
const imageSize = computed(() => {
  return props.size === 'm' ? 16 : 12
})

const is = computed(() => {
  return props.to ? 'link' : 'div'
})
</script>

<style lang="postcss" scoped>
.tag-with-image {
  @apply flex items-center font-medium rounded-xs leading-6;

  &.m {
    @apply px-3 h-8 text-sm;

    i,
    picture {
      @apply mr-[10px];
    }

    i {
      @apply text-base leading-none;
    }
  }

  &.s {
    @apply px-2 h-6 text-xs;

    i,
    picture {
      @apply mr-1;
    }
  }

  &.livestorm-blue {
    @apply bg-livestorm-blue-100 text-livestorm-blue-700;

    i {
      @apply text-livestorm-blue-700;
    }
  }

  &.pale-blue {
    @apply bg-blizzard-100 text-blizzard-700;

    i {
      @apply text-blizzard-700;
    }
  }

  &.grey-blue {
    @apply bg-grey-blue-100 text-grey-blue-700;

    i {
      @apply text-grey-blue-700;
    }
  }

  &.pale-red {
    @apply bg-sunburst-red-100 text-sunburst-red-700;

    i {
      @apply text-sunburst-red-700;
    }
  }

  &.skin-tone {
    @apply bg-sirocco-100 text-sirocco-700;

    i {
      @apply text-sirocco-700;
    }
  }

  &.carmin {
    @apply bg-live-red-100 text-live-red-700;

    i {
      @apply text-live-red-700;
    }
  }

  &.green {
    @apply bg-winter-green-100 text-winter-green-700;

    i {
      @apply text-winter-green-700;
    }
  }

  picture {
    @apply rounded-none;
  }
}
</style>
