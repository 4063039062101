import HmacSHA256 from 'crypto-js/hmac-sha256'

export default defineNuxtPlugin(() => {
  const { cookiesEnabledIds } = useCookieControl()
  const { $device } = useNuxtApp()

  const { public: publicRuntimeConfig } = useRuntimeConfig()
  const id = publicRuntimeConfig.segmentKey

  // Create a queue, but don't obliterate an existing one!
  const analytics = (window.analytics = window.analytics || [])
  // If the real analytics.js is already on the page return.
  if (analytics.initialize) return

  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    if (window.console && console.error) {
      console.error('Segment snippet included twice.')
    }
    return
  }
  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true
  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
    'addSourceMiddleware',
    'addIntegrationMiddleware',
    'setAnonymousId',
    'addDestinationMiddleware',
  ]
  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function (method) {
    return function () {
      var args = Array.prototype.slice.call(arguments)
      args.unshift(method)
      analytics.push(args)
      return analytics
    }
  }
  // For each of our methods, generate a queueing stub.
  for (var i = 0; i < analytics.methods.length; i++) {
    var key = analytics.methods[i]
    analytics[key] = analytics.factory(key)
  }
  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.
  analytics.load = function (key, options) {
    // Create an async script element based on your key.
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.defer = true
    script.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`
    // Insert our script next to the first script element.
    const first = document.getElementsByTagName('script')[0]
    first.parentNode.insertBefore(script, first)
    analytics._loadOptions = options
  }
  analytics._writeKey = id

  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = '4.15.3'

  analytics.ready(() => {
    if (!$device.isMobile) {
      const userId = window.analytics?.user()?.id()
      if (userId) {
        const hmac = HmacSHA256(userId, publicRuntimeConfig.intercomAppSecret)
        const userHash = hmac.toString()
        window.analytics.identify(
          userId,
          {},
          {
            Intercom: {
              user_hash: userHash,
            },
          },
        )
      }
    }
  })

  return {
    provide: {
      segment: {
        consent: () => {
          if (!cookiesEnabledIds.value) {
            analytics.load(id, {
              integrations: {
                'All': false,
                'Amplitude': true,
                'Attribution': true,
                'Clearbit Reveal': true,
                'Customer.io': false,
                'Customer.io (Actions)': false,
                'Google Analytics 4 Web': true,
                'Intercom': !$device.isMobile,
                'Mutiny': !$device.isMobile,
                'Piwik': true,
                'Segment.io': true,
                'Webhooks': true,
              },
            })
          }
          else {
            analytics.load(id, {
              integrations: {
                'All': false,
                'Amplitude': cookiesEnabledIds.value.includes('analytics-internal-use'),
                'Attribution': cookiesEnabledIds.value.includes('analytics-internal-use'),
                'Clearbit Reveal': cookiesEnabledIds.value.includes('analytics') || cookiesEnabledIds.value.includes('website-personalization'),
                'Customer.io': cookiesEnabledIds.value.includes('analytics'),
                'Customer.io (Actions)': cookiesEnabledIds.value.includes('analytics'),
                'Google Analytics 4 Web': cookiesEnabledIds.value.includes('analytics-internal-use'),
                'Intercom': !$device.isMobile && cookiesEnabledIds.value.includes('live-chat'),
                'Mutiny': !$device.isMobile && cookiesEnabledIds.value.includes('website-personalization'),
                'Piwik': cookiesEnabledIds.value.includes('analytics-internal-use'),
                'Segment.io': cookiesEnabledIds.value.includes('strictly-necessary'),
                'Webhooks': cookiesEnabledIds.value.includes('analytics'),
              },
            })
          }
        },
      },
    },
  }
})
