<template>
  <UiComponentButton
    v-bind="{
      ...props,
      to: parsedUrl,
    }"
    @click="onClick"
  >
    <slot />
  </UiComponentButton>
</template>

<script setup>
// Composables
const { track } = useAnalyticsJS()
const { public: publicRuntimeConfig } = useRuntimeConfig()

// Props
const props = defineProps({
  action: {
    default() {
      return null
    },
    required: false,
    type: String,
  },
  attributes: {
    default() {
      return {}
    },
    required: false,
    type: Object,
  },
  disabled: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  download: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  forceNoFollow: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  forceNewTab: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  iconPosition: {
    default() {
      return 'icon-right'
    },
    required: false,
    type: String,
    validator(value) {
      return ['icon-left', 'icon-right'].indexOf(value) !== -1
    },
  },
  label: {
    default() {
      return undefined
    },
    type: String,
    required: false,
  },
  size: {
    default() {
      return 'm'
    },
    required: false,
    type: String,
    validator(value) {
      return ['m', 'l'].indexOf(value) !== -1
    },
  },
  theme: {
    default() {
      return 'light'
    },
    required: false,
    type: String,
    validator(value) {
      return ['dark', 'light'].indexOf(value) !== -1
    },
  },
  type: {
    default() {
      return 'primary'
    },
    required: false,
    type: String,
    validator(value) {
      return (
        ['primary', 'secondary', 'text', 'no-style'].indexOf(value) !== -1
      )
    },
  },
  to: {
    default() {
      return ''
    },
    required: false,
    type: String,
  },
})

// States
const forms = useState('forms')

// Computed Properties
const parsedUrl = computed(() => {
  if (
    publicRuntimeConfig.appBaseUrl.includes('livestorm.io')
    && props.to.includes('app.livestorm.co')
  ) {
    return props.to.replace('app.livestorm.co', publicRuntimeConfig.appBaseUrl)
  }
  return props.to || ''
})

// Methods
function onClick(event) {
  if (props.disabled) {
    event.preventDefault()
    return
  }

  if (parsedUrl.value === '#form-demo') {
    forms.value.demo.isVisible = true
    event.preventDefault()
  }

  if (parsedUrl.value === '#form-ebook') {
    forms.value.offer.isVisible = true
    event.preventDefault()
  }

  if (props.action) {
    track(props.action, {
      category: 'Button',
      label: props.label,
      ...props.attributes,
    })
  }
}
</script>
