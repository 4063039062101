export default defineNuxtPlugin(() => {
  const { cookiesEnabledIds } = useCookieControl()
  const { $device } = useNuxtApp()

  return {
    provide: {
      mutiny: {
        load: () => {
          const globalScript = document.createElement('script')
          globalScript.addEventListener('load', () => {
            window.mutiny.client.defaultOptOut()

            if (!$device.isMobile && cookiesEnabledIds.value?.includes('website-personalization')) {
              window.mutiny.client.optIn()
            }

            const experiencesScript = document.createElement('script')
            experiencesScript.src
              = 'https://client-registry.mutinycdn.com/personalize/client/c8d63c555f1fd012.js'
            experiencesScript.type = 'text/javascript'

            document.head.appendChild(experiencesScript)
          })
          globalScript.src = '/scripts/mutiny.js'
          globalScript.type = 'text/javascript'

          document.head.appendChild(globalScript)
        },
      },
    },
  }
})
